<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" @close="close" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white shadow-xl transition-all">
              <!-- Header -->
              <div class="border-b border-gray-200">
                <div class="flex items-center justify-between px-6 py-4">
                  <div class="flex items-center space-x-3">
                    <div class="flex h-10 w-10 items-center justify-center rounded-full" 
                         :class="getStatusBackgroundClass">
                      <component 
                        :is="getStatusIcon" 
                        class="h-5 w-5"
                        :class="getStatusIconClass"
                      />
                    </div>
                    <div>
                      <DialogTitle as="h3" class="text-lg font-semibold text-gray-900">
                        #{{ item?.name }}
                      </DialogTitle>
                      <p class="text-sm text-gray-500">
                        {{ getStatusText(item?.state) }}
                      </p>
                    </div>
                  </div>
                  <button 
                    @click="close"
                    class="rounded-lg p-1 text-gray-400 hover:text-gray-500"
                  >
                    <XMarkIcon class="h-6 w-6" />
                  </button>
                </div>
              </div>

              <!-- Content -->
              <div class="px-6 py-4 space-y-6">
                <!-- Customer & Vehicle Section -->
                <div class="grid grid-cols-2 gap-6">
                  <div>
                    <h4 class="text-sm font-medium text-gray-500 mb-1">Customer</h4>
                    <div class="bg-gray-50 rounded-lg p-3">
                      <p class="font-medium">{{ item?.customer?.name }}</p>
                    </div>
                  </div>
                  <div>
                    <h4 class="text-sm font-medium text-gray-500 mb-1">Vehicle</h4>
                    <div class="bg-gray-50 rounded-lg p-3">
                      <p class="font-medium">{{ item?.car?.plate }}</p>
                      <p class="text-sm text-gray-500">{{ item?.car?.brand }} {{ item?.car?.type }}</p>
                    </div>
                  </div>
                </div>

                <!-- Timeline Section -->
                <div>
                  <h4 class="text-sm font-medium text-gray-500 mb-3">Timeline</h4>
                  <div class="relative">
                    <!-- Timeline Line -->
                    <div class="absolute top-0 left-3.5 bottom-0 w-px bg-gray-200"></div>

                    <div class="space-y-6">
                      <!-- Departure -->
                      <div class="relative flex items-start">
                        <div 
                          class="absolute left-0 rounded-full h-8 w-8 flex items-center justify-center"
                          :class="item?.timestamps?.departure ? 'bg-blue-100' : 'bg-gray-100'"
                        >
                          <TruckIcon 
                            class="h-4 w-4"
                            :class="item?.timestamps?.departure ? 'text-blue-600' : 'text-gray-400'"
                          />
                        </div>
                        <div class="ml-12">
                          <div class="flex items-center">
                            <p class="font-medium">Departure</p>
                            <div v-if="item?.timestamps?.departure" 
                                 class="ml-2 px-2 py-1 bg-blue-50 text-blue-700 rounded-full text-xs">
                              Completed
                            </div>
                          </div>
                          <p class="text-sm text-gray-500">
                            {{ formatDate(item?.timestamps?.departure) || 'Not yet departed' }}
                          </p>
                        </div>
                      </div>

                      <!-- Return -->
                      <div class="relative flex items-start">
                        <div 
                          class="absolute left-0 rounded-full h-8 w-8 flex items-center justify-center"
                          :class="item?.timestamps?.return ? 'bg-green-100' : 'bg-gray-100'"
                        >
                          <ArrowPathIcon 
                            class="h-4 w-4"
                            :class="item?.timestamps?.return ? 'text-green-600' : 'text-gray-400'"
                          />
                        </div>
                        <div class="ml-12">
                          <div class="flex items-center">
                            <p class="font-medium">Return</p>
                            <div v-if="item?.timestamps?.return" 
                                 class="ml-2 px-2 py-1 bg-green-50 text-green-700 rounded-full text-xs">
                              Completed
                            </div>
                          </div>
                          <p class="text-sm text-gray-500">
                            {{ formatDate(item?.timestamps?.return) || 'Not yet returned' }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Duration & Partman -->
                <div class="grid grid-cols-2 gap-6">
                  <div>
                    <h4 class="text-sm font-medium text-gray-500 mb-1">Duration</h4>
                    <div class="bg-gray-50 rounded-lg p-3">
                      <div class="flex items-center space-x-2">
                        <ClockIcon class="h-4 w-4 text-gray-400" />
                        <span class="font-medium">{{ item?.duration?.display || '-' }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4 class="text-sm font-medium text-gray-500 mb-1">Partman</h4>
                    <div class="bg-gray-50 rounded-lg p-3">
                      <div class="flex items-center space-x-2">
                        <div class="h-6 w-6 rounded-full bg-gray-200 flex items-center justify-center">
                          <UserIcon class="h-4 w-4 text-gray-500" />
                        </div>
                        <span class="font-medium">{{ item?.partman?.name || '-' }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Notes -->
                <div v-if="item?.notes">
                  <h4 class="text-sm font-medium text-gray-500 mb-1">Notes</h4>
                  <div class="bg-gray-50 rounded-lg p-3">
                    <p class="text-sm text-gray-700 whitespace-pre-line">{{ item.notes }}</p>
                  </div>
                </div>
              </div>

              <!-- Footer -->
              <div class="border-t border-gray-200 px-6 py-4">
                <div class="flex justify-end">
                  <button
                    @click="close"
                    class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
                  >
                    Close
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { 
  XMarkIcon, 
  TruckIcon, 
  ArrowPathIcon,
  ClockIcon,
  UserIcon, 
  CheckCircleIcon,
  XCircleIcon
} from '@heroicons/vue/24/outline'

const props = defineProps({
  show: Boolean,
  item: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['close'])

// Computed
const getStatusIcon = computed(() => {
  switch (props.item?.state) {
    case 'returned':
      return CheckCircleIcon
    case 'cancel':
      return XCircleIcon
    case 'departed':
      return TruckIcon
    default:
      return ClockIcon
  }
})

const getStatusBackgroundClass = computed(() => {
  switch (props.item?.state) {
    case 'returned':
      return 'bg-green-100'
    case 'cancel':
      return 'bg-red-100'
    case 'departed':
      return 'bg-blue-100'
    default:
      return 'bg-gray-100'
  }
})

const getStatusIconClass = computed(() => {
  switch (props.item?.state) {
    case 'returned':
      return 'text-green-600'
    case 'cancel':
      return 'text-red-600'
    case 'departed':
      return 'text-blue-600'
    default:
      return 'text-gray-600'
  }
})

// Methods
const getStatusText = (status) => {
  const statusMap = {
    draft: 'Draft',
    departed: 'Berangkat',
    returned: 'Kembali',
    cancel: 'Dibatalkan'
  }
  return statusMap[status] || status
}

const formatDate = (dateString) => {
  if (!dateString) return '-'
  try {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return '-'
    
    return new Intl.DateTimeFormat('id-ID', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date)
  } catch (error) {
    console.error('Error formatting date:', error)
    return '-'
  }
}

const close = () => {
  emit('close')
}
</script>