<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-50" @close="onClose">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/30" />
      </TransitionChild>

      <div class="fixed inset-0 flex items-center justify-center p-4">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
          <DialogPanel class="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <DialogTitle class="text-lg font-medium text-gray-900 mb-4">
              Sampling Details for Order #{{ selectedOrder?.name }}
            </DialogTitle>

            <div v-if="loading" class="flex items-center justify-center py-8">
              <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>

            <div v-else class="space-y-6">
              <!-- Order Info -->
              <div class="bg-gray-50 p-4 rounded-lg">
                <div class="grid grid-cols-2 gap-4">
                  <div>
                    <div class="text-sm text-gray-600">Car Info</div>
                    <div class="font-medium">{{ selectedOrder?.car_info?.plate }}</div>
                    <div class="text-sm">{{ selectedOrder?.car_info?.brand }} {{ selectedOrder?.car_info?.type }}</div>
                  </div>
                  <div>
                    <div class="text-sm text-gray-600">Service Date</div>
                    <div class="font-medium">{{ formatDate(selectedOrder?.date) }}</div>
                  </div>
                </div>
              </div>

              <!-- Samplings List -->
              <div class="border rounded-lg divide-y">
                <div v-for="sampling in samplings" :key="sampling.id" class="p-4 space-y-3">
                  <div class="flex justify-between items-start">
                    <div class="flex-1">
                      <div class="font-medium">{{ sampling.sop.code }} - {{ sampling.sop.name }}</div>
                      <div class="mt-1 flex flex-wrap gap-2">
                        <span class="px-2 py-1 rounded-full text-xs font-medium" :class="sampling.sop.is_sa ? 'bg-purple-100 text-purple-800' : 'bg-green-100 text-green-800'">
                          {{ sampling.sop.is_sa ? 'Service Advisor' : 'Mechanic' }}
                        </span>
                        <span :class="[
                          'px-2 py-1 rounded-full text-xs font-medium',
                          sampling.state === 'draft' ? 'bg-gray-100 text-gray-800' : '',
                          sampling.state === 'done' && sampling.result === 'pass' ? 'bg-green-100 text-green-800' : '',
                          sampling.state === 'done' && sampling.result === 'fail' ? 'bg-red-100 text-red-800' : ''
                        ]">
                          {{ sampling.state === 'done' ? (sampling.result === 'pass' ? 'Passed' : 'Failed') : 'Draft' }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="text-sm space-y-2">
                    <div v-if="sampling.employee.service_advisor.length > 0">
                      <span class="text-gray-600">Service Advisor:</span>
                      <span class="font-medium ml-1">{{ sampling.employee.service_advisor[0].name }}</span>
                    </div>
                    <div v-if="sampling.employee.mechanic.length > 0">
                      <span class="text-gray-600">Mechanic:</span>
                      <span class="font-medium ml-1">{{ sampling.employee.mechanic[0].name }}</span>
                    </div>
                  </div>

                  <div v-if="sampling.notes" class="bg-gray-50 p-3 rounded-lg text-sm">
                    <div class="font-medium text-gray-700 mb-1">Notes:</div>
                    <div class="text-gray-600">{{ sampling.notes }}</div>
                  </div>

                  <div class="text-sm text-gray-500">
                    Controller: {{ sampling.controller.name }}
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 flex justify-end">
              <button type="button" class="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200" @click="onClose">
                Close
              </button>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, watch } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue'
import apiClient from '../../config/api'
import { toast } from 'vue-sonner'

const props = defineProps({
  isOpen: Boolean,
  selectedOrder: Object,
  onClose: Function
})

const loading = ref(false)
const samplings = ref([])

const fetchSamplingDetails = async () => {
  if (!props.selectedOrder?.id) return
  
  try {
    loading.value = true
    const response = await apiClient.post('/web/sop/sampling/list', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: 1,
        limit: 25,
        search: props.selectedOrder.name,
        month: new Date(props.selectedOrder.date).toISOString().slice(0, 7),
        is_sa: null
      }
    })

    if (response?.data?.result?.status === 'success') {
      samplings.value = response.data.result.data.rows.filter(
        sampling => sampling.sale_order.id === props.selectedOrder.id
      )
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to fetch sampling details')
    }
  } catch (error) {
    console.error('Error fetching sampling details:', error)
    toast.error('Failed to load sampling details: ' + error.message)
  } finally {
    loading.value = false
  }
}

const formatDate = (dateString) => {
  if (!dateString) return '-'
  return new Date(dateString).toLocaleDateString('id-ID', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

watch(() => props.isOpen, (newVal) => {
  if (newVal) {
    fetchSamplingDetails()
  }
})
</script>