import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '../components/LoginPage.vue'
import LeadTime from '../components/LeadTime.vue'
import Dashboard from '../components/Dashboard.vue'
import Reports from '../components/Reports.vue'
import DetailReport from '../components/DetailReport.vue'
import Setting from '../components/Setting.vue'
import SOP from '../components/master/SOP.vue'
import Sampling from '../components/sop/AvailableOrders.vue'
import SOPSampling from '../components/sop/SOPSampling.vue'
import SOPSummary from '../components/sop/SamplingSummary.vue'
import Orders from '../components/sparepart/SelectOrder.vue'
import Purchase from '../components/sparepart/PartPurchase.vue'
import Analytics from '../components/sparepart/Statistics.vue'
import { useAuthStore } from '../stores/auth'

const routes = [
  {
    path: '/',
    redirect: to => {
      const authStore = useAuthStore()
      return authStore.isAuthenticated ? '/dashboard' : '/login'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { requiresAuth: false },
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.isAuthenticated) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: false },
  },
  {
    path: '/lead-time',
    name: 'LeadTime',
    component: LeadTime,
    meta: { requiresAuth: false },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: { requiresAuth: false },
  },
  {
    path: '/reports/:id',
    name: 'report-detail',
    component: DetailReport,
    meta: { requiresAuth: false },
    props: true
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
    meta: { requiresAuth: false },
  },
  {
    path: '/sop/master',
    name: 'SOP',
    component: SOP,
    meta: { requiresAuth: false },
  },
  {
    path: '/sop/orders',
    name: 'Sampling',
    component: Sampling,
    meta: { requiresAuth: false },
  },
  {
    path: '/sop/validation',
    name: 'Validation',
    component: SOPSampling,
    meta: { requiresAuth: false },
  },
  {
    path: '/sop/summary',
    name: 'Summary',
    component: SOPSummary,
    meta: { requiresAuth: false },
  },
  {
    path: '/sparepart/orders',
    name: 'Orders',
    component: Orders,
    meta: { requiresAuth: false },
  },
  {
    path: '/sparepart/purchases',
    name: 'Purchase',
    component: Purchase,
    meta: { requiresAuth: false },
  },
  {
    path: '/sparepart/purchases/analytics',
    name: 'Analytics',
    component: Analytics,
    meta: { requiresAuth: false },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: to => {
      const authStore = useAuthStore()
      return authStore.isAuthenticated ? '/dashboard' : '/login'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// / Navigation guard
// Navigation guard for auth check
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  const isAuthenticated = localStorage.getItem('uid')
  
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login')
  } else if (to.path === '/login' && isAuthenticated) {
    next('/')
  } else {
    next()
  }
})



export default router