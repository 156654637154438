<template>
  <Toaster position="top-right" :duration="3000" :richColors="true" />
  
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Memuat data...</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="space-y-6">
        <!-- Header -->
        <div class="flex flex-col md:flex-row justify-between items-center">
          <div class="flex items-center space-x-4 mb-4 md:mb-0">
            <h1 class="text-xl font-semibold">Part Purchase Tracking</h1>
            <!-- Stats Summary -->
            <div class="flex space-x-4 text-sm">
              <div class="px-3 py-1 bg-blue-50 text-blue-700 rounded-full">
                {{ stats.departed || 0 }} Pemberangkatan
              </div>
              <div class="px-3 py-1 bg-green-50 text-green-700 rounded-full">
                {{ stats.returned || 0 }} Selesai
              </div>
            </div>
          </div>
          <div class="text-gray-600 font-medium">
            {{ formatDate(new Date()) }}
          </div>
        </div>

        <!-- Search and Filter -->
        <div class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
          <div class="relative w-full md:w-96">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Cari nomor order atau plat..."
              class="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              @input="handleSearch"
            >
            <MagnifyingGlassIcon class="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <div class="flex gap-2">
            <button 
              v-for="filter in filters" 
              :key="filter.value"
              class="px-4 py-2 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              :class="[
                currentFilter === filter.value 
                  ? 'bg-gray-900 text-white' 
                  : 'bg-white border hover:bg-gray-50'
              ]"
              @click="handleFilterChange(filter.value)"
            >
              {{ filter.label }}
              <span class="ml-2 px-2 py-0.5 rounded-full text-xs" 
                    :class="currentFilter === filter.value ? 'bg-gray-800' : 'bg-gray-100'">
                {{ filterCounts[filter.value] || 0 }}
              </span>
            </button>
          </div>
        </div>

        <!-- Table Section -->
        <div v-if="rows.length > 0" class="overflow-x-auto rounded-lg border">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Order Info
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Vehicle
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Partman
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status & Duration
                </th>
                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="item in rows" :key="item.id" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex flex-col">
                    <div class="text-sm font-medium text-gray-900">#{{ item.name }}</div>
                    <div class="text-sm text-gray-500">{{ formatDate(item.timestamps.departure) }}</div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex flex-col">
                    <div class="text-sm font-medium text-gray-900">{{ item.car.plate }}</div>
                    <div class="text-sm text-gray-500">{{ item.car.brand }} {{ item.car.type }}</div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-8 w-8 bg-gray-100 rounded-full flex items-center justify-center">
                      <UserIcon class="h-4 w-4 text-gray-500" />
                    </div>
                    <div class="ml-3">
                      <div class="text-sm font-medium text-gray-900">
                        {{ item.partman?.name || '-' }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex flex-col">
                    <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                          :class="getStatusClasses(item.state)">
                      {{ getStatusText(item.state) }}
                    </span>
                    <div v-if="item.duration?.display" class="text-sm text-gray-500 mt-1">
                      {{ item.duration.display }}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right space-x-2">
                  <button
                    v-if="item.state === 'draft'"
                    @click="openActionModal('depart', item)"
                    class="inline-flex items-center px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <TruckIcon class="h-4 w-4 mr-1" />
                    Pemberangkatan
                  </button>
                  <button
                    v-if="item.state === 'departed'"
                    @click="openActionModal('return', item)"
                    class="inline-flex items-center px-3 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    <ArrowPathIcon class="h-4 w-4 mr-1" />
                    Selesai
                  </button>
                  <button
                    v-if="['draft', 'departed'].includes(item.state)"
                    @click="openActionModal('cancel', item)"
                    class="inline-flex items-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <XMarkIcon class="h-4 w-4 mr-1" />
                    Cancel
                  </button>
                  <button
                    @click="showDetail(item)"
                    class="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <EyeIcon class="h-4 w-4 mr-1" />
                    Detail
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Empty State -->
        <div v-else-if="!loading" class="text-center py-12">
          <div class="mx-auto h-12 w-12 text-gray-400">
            <InboxIcon class="h-12 w-12" />
          </div>
          <h3 class="mt-2 text-sm font-medium text-gray-900">No Purchase Records</h3>
          <p class="mt-1 text-sm text-gray-500">
            {{ searchQuery ? 'No results match your search' : 'Get started by creating a new purchase record' }}
          </p>
        </div>

        <!-- Pagination -->
        <div v-if="pagination && rows.length > 0" 
             class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div class="flex flex-1 justify-between sm:hidden">
            <button
              @click="handlePageChange(pagination.current_page - 1)"
              :disabled="!pagination.has_previous"
              class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              @click="handlePageChange(pagination.current_page + 1)"
              :disabled="!pagination.has_next"
              class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Showing
                <span class="font-medium">{{ startNumber }}</span>
                to
                <span class="font-medium">{{ endNumber }}</span>
                of
                <span class="font-medium">{{ pagination.total_items }}</span>
                results
              </p>
            </div>
            <div>
              <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <button
                  @click="handlePageChange(pagination.current_page - 1)"
                  :disabled="!pagination.has_previous"
                  class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span class="sr-only">Previous</span>
                  <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                </button>
                <!-- Pages -->
                <template v-for="page in pageNumbers" :key="page">
                  <span v-if="page === '...'" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                    ...
                  </span>
                  <button
                    v-else
                    @click="handlePageChange(page)"
                    :class="[
                      pagination.current_page === page
                        ? 'relative z-10 inline-flex items-center bg-blue-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                        : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                    ]"
                  >
                    {{ page }}
                  </button>
                </template>
                <button
                  @click="handlePageChange(pagination.current_page + 1)"
                  :disabled="!pagination.has_next"
                  class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span class="sr-only">Next</span>
                  <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Action Modal -->
    <ActionModal
      v-if="showActionModal"
      :show="showActionModal"
      :type="actionType"
      :title="getActionTitle"
      @close="closeActionModal"
      @submit="handleActionSubmit"
    />

    <!-- Detail Modal -->
    <DetailModal 
      v-if="showDetailDialog"
      :show="showDetailDialog"
      :item="selectedItem"
      @close="closeDetailDialog"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { Toaster, toast } from 'vue-sonner'
import { 
  MagnifyingGlassIcon, 
  UserIcon,
  TruckIcon,
  ArrowPathIcon,
  XMarkIcon,
  EyeIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  InboxIcon
} from '@heroicons/vue/24/outline'
import debounce from 'lodash/debounce'
import ActionModal from './ActionModal.vue'
import DetailModal from './DetailModal.vue'
import apiClient from '@/config/api'

// State
const loading = ref(false)
const rows = ref([])
const pagination = ref(null)
const searchQuery = ref('')
const currentFilter = ref('all')
const selectedItem = ref(null)
const filterCounts = ref({})
const stats = ref({})

// Modal state
const showActionModal = ref(false)
const showDetailDialog = ref(false)
const actionType = ref(null)

// Constants
const filters = [
  { label: 'All', value: 'all' },
  { label: 'Draft', value: 'draft' },
  { label: 'Departed', value: 'departed' },
  { label: 'Returned', value: 'returned' }
]

// Computed
const startNumber = computed(() => {
  if (!pagination.value) return 0
  return ((pagination.value.current_page - 1) * pagination.value.items_per_page) + 1
})

const endNumber = computed(() => {
  if (!pagination.value) return 0
  return Math.min(
    startNumber.value + pagination.value.items_per_page - 1,
    pagination.value.total_items
  )
})

const pageNumbers = computed(() => {
  if (!pagination.value) return []
  const current = pagination.value.current_page
  const total = pagination.value.total_pages
  const delta = 2
  const pages = []
  
  for (let i = 1; i <= total; i++) {
    if (
      i === 1 || 
      i === total || 
      (i >= current - delta && i <= current + delta)
    ) {
      pages.push(i)
    } else if (pages[pages.length - 1] !== '...') {
      pages.push('...')
    }
  }
  
  return pages
})

const getActionTitle = computed(() => {
  const titles = {
    depart: 'Record Departure',
    return: 'Record Return',
    cancel: 'Cancel Purchase'
  }
  return titles[actionType.value] || ''
})

// Methods
const fetchData = async (params = {}) => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/part-purchase/table', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: params.page || 1,
        limit: params.limit || 20,
        search: searchQuery.value,
        filter: currentFilter.value
      }
    })

    if (response?.data?.result?.status === 'success') {
      const { rows: fetchedRows, pagination: paginationData, summary } = response.data.result.data
      rows.value = fetchedRows
      pagination.value = paginationData
      updateStats(summary)
      updateFilterCounts(fetchedRows)
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    toast.error('Failed to load data')
  } finally {
    loading.value = false
  }
}

const updateStats = (summary) => {
  stats.value = {
    departed: summary.departed || 0,
    returned: summary.returned || 0
  }
}

const updateFilterCounts = (data) => {
  filterCounts.value = {
    all: data.length,
    draft: data.filter(item => item.state === 'draft').length,
    departed: data.filter(item => item.state === 'departed').length,
    returned: data.filter(item => item.state === 'returned').length
  }
}

// Action Handlers
const openActionModal = (type, item) => {
  actionType.value = type
  selectedItem.value = item
  showActionModal.value = true
}

const closeActionModal = () => {
  showActionModal.value = false
  actionType.value = null
  selectedItem.value = null
}

const handleActionSubmit = async (formData) => {
  try {
    loading.value = true
    const { time, notes } = formData
    let response

    switch (actionType.value) {
      case 'depart':
        response = await apiClient.post(`/web/part-purchase/${selectedItem.value.id}/depart`, {
          jsonrpc: "2.0",
          method: "call",
          params: { startTime: time, notes }
        })
        break
      case 'return':
        response = await apiClient.post(`/web/part-purchase/${selectedItem.value.id}/return`, {
          jsonrpc: "2.0",
          method: "call",
          params: { endTime: time, notes }
        })
        break
      case 'cancel':
        response = await apiClient.post(`/web/part-purchase/${selectedItem.value.id}/cancel`, {
          jsonrpc: "2.0",
          method: "call",
          params: { notes }
        })
        break
    }

    if (response?.data?.result?.status === 'success') {
      toast.success(`Successfully ${actionType.value}ed purchase`)
      await fetchData()
    }
  } catch (error) {
    console.error(`Error in ${actionType.value}:`, error)
    toast.error(`Failed to ${actionType.value} purchase`)
  } finally {
    loading.value = false
    closeActionModal()
  }
}

// Detail Handlers
const showDetail = async (item) => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/part-purchase/detail', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        purchase_id: item.id
      }
    })

    if (response?.data?.result?.status === 'success') {
      selectedItem.value = response.data.result.data
      showDetailDialog.value = true
    }
  } catch (error) {
    console.error('Error loading details:', error)
    toast.error('Failed to load purchase details')
  } finally {
    loading.value = false
  }
}

const closeDetailDialog = () => {
  showDetailDialog.value = false
  selectedItem.value = null
}

// UI Helpers
const getStatusClasses = (status) => {
  return {
    draft: 'bg-gray-100 text-gray-800',
    departed: 'bg-blue-100 text-blue-800',
    returned: 'bg-green-100 text-green-800',
    cancel: 'bg-red-100 text-red-800'
  }[status] || 'bg-gray-100 text-gray-800'
}

const getStatusText = (status) => {
  const statusMap = {
    draft: 'Draft',
    departed: 'Berangkat',
    returned: 'Kembali',
    cancel: 'Dibatalkan'
  }
  return statusMap[status] || status
}

const formatDate = (dateString) => {
  if (!dateString) return '-'
  try {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return '-'
    
    return new Intl.DateTimeFormat('id-ID', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date)
  } catch (error) {
    console.error('Error formatting date:', error)
    return '-'
  }
}

// Event Handlers
const handleSearch = debounce(() => {
  fetchData({ page: 1 })
}, 300)

const handleFilterChange = (filter) => {
  currentFilter.value = filter
  fetchData({ page: 1 })
}

const handlePageChange = (page) => {
  fetchData({ page })
}

// Lifecycle
onMounted(() => {
  fetchData()
})
</script>

<style scoped>
.status-badge {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;
}
</style>