<!-- StatusBadge.vue -->
<template>
  <span 
    class="px-3 py-1 rounded-full text-sm font-medium inline-flex items-center gap-2"
    :class="statusClasses[status.code] || defaultClass"
  >
    <component 
      :is="statusIcon" 
      class="w-4 h-4"
      v-if="showIcon"
    />
    {{ status.text }}
  </span>
</template>

<script setup>
import { computed } from 'vue'
import {
  ClockIcon,
  CheckCircleIcon,
  TruckIcon,
  XCircleIcon
} from '@heroicons/vue/24/outline'

const props = defineProps({
  status: {
    type: Object,
    required: true,
    validator: (value) => {
      return value.hasOwnProperty('code') && value.hasOwnProperty('text')
    }
  },
  showIcon: {
    type: Boolean,
    default: true
  }
})

const statusClasses = computed(() => {
  switch (props.status.code) {
    case 'none':
      return 'bg-gray-100 text-gray-600'
    case 'draft':
      return 'bg-blue-100 text-blue-700'
    case 'departed':
      return 'bg-yellow-100 text-yellow-700'
    case 'completed':
      return 'bg-green-100 text-green-700'
    default:
      return 'bg-gray-100 text-gray-600'
  }
})

const statusIcons = {
  need_part: ClockIcon,
  active: TruckIcon,
  completed: CheckCircleIcon,
  departed: TruckIcon,
  cancelled: XCircleIcon
}

const defaultClass = 'bg-gray-100 text-gray-800'

const statusIcon = computed(() => statusIcons[props.status.code])
</script>