<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-50" @close="handleClose">
      <!-- Dialog Overlay -->
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/30" />
      </TransitionChild>

      <!-- Dialog Content -->
      <div class="fixed inset-0 flex items-center justify-center p-4">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="w-full max-w-3xl bg-white rounded-xl shadow-xl overflow-hidden">
            <!-- Header -->
            <div class="px-6 py-4 border-b">
              <div class="flex items-center justify-between">
                <div>
                  <DialogTitle as="h3" class="text-lg font-semibold text-gray-900">
                    {{ dialogTitle }}
                  </DialogTitle>
                  <p class="mt-1 text-sm text-gray-500">
                    {{ sampling?.sale_order?.name }} - {{ sampling?.sop?.code }}
                  </p>
                </div>
                <span 
                  class="px-3 py-1 rounded-full text-sm font-medium"
                  :class="sampling?.sop?.is_sa ? 'bg-purple-100 text-purple-800' : 'bg-green-100 text-green-800'"
                >
                  {{ sampling?.sop?.is_sa ? 'Service Advisor' : 'Mekanik' }}
                </span>
              </div>
            </div>

            <div class="p-6 space-y-6">
              <!-- Vehicle & Employee Info -->
              <div class="grid grid-cols-2 gap-6">
                <!-- Vehicle Info -->
                <div class="space-y-2">
                  <div class="text-sm font-medium text-gray-700">Informasi Kendaraan</div>
                  <div class="px-4 py-3 bg-white rounded-lg space-y-2 shadow-sm border border-gray-200">
                    <div class="flex items-center gap-2">
                      <span class="px-2 py-0.5 bg-gray-50 rounded font-medium text-sm border border-gray-200">
                        {{ sampling?.sale_order?.car_info?.plate }}
                      </span>
                    </div>
                    <div class="text-sm text-gray-600">
                      {{ sampling?.sale_order?.car_info?.brand }} 
                      {{ sampling?.sale_order?.car_info?.type }}
                    </div>
                  </div>
                </div>

                <!-- Employee Info -->
                <div class="space-y-2">
                  <div class="text-sm font-medium text-gray-700">
                    {{ sampling?.sop?.is_sa ? 'Service Advisor' : 'Mekanik' }}
                  </div>
                  <div class="px-4 py-3 bg-white rounded-lg shadow-sm border border-gray-200">
                    <div class="flex items-center gap-2">
                      <UserCircleIcon class="w-5 h-5 text-gray-400" />
                      <span class="font-medium">{{ getEmployeeName }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- View Result Mode -->
              <div v-if="isValidated && !props.isEdit">
                <div class="space-y-4">
                  <!-- Result Badge in View Mode -->
                  <div class="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm border border-gray-200">
                    <div class="text-sm font-medium text-gray-700">Hasil Validasi</div>
                    <span 
                      class="px-3 py-1.5 rounded-lg text-sm font-medium inline-flex items-center gap-2"
                      :class="getResultClass"
                    >
                      <component :is="getResultIcon" class="w-5 h-5" />
                      {{ result === 'pass' ? 'Lulus' : 'Tidak Lulus' }}
                    </span>
                  </div>

                  <!-- Notes in View Mode -->
                  <div class="space-y-2">
                    <div class="text-sm font-medium text-gray-700">Catatan</div>
                    <div class="px-4 py-3 bg-white rounded-lg text-sm text-gray-600 min-h-[80px] shadow-sm border border-gray-200">
                      {{ notes || 'Tidak ada catatan' }}
                    </div>
                  </div>

                  <!-- History & Timestamps -->
                  <!-- History & Timestamps -->
                  <div class="rounded-lg bg-white shadow-sm border border-gray-200">
                    <div class="px-4 py-3 bg-gray-50 border-b border-gray-200">
                      <h4 class="text-sm font-medium text-gray-900">Riwayat Sampling</h4>
                    </div>
                    <div class="divide-y divide-gray-200">
                      <!-- Created -->
                      <div class="px-4 py-3 flex flex-col gap-1">
                        <div class="flex items-center justify-between text-sm">
                          <div class="flex items-center gap-2">
                            <div class="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center">
                              <PlusCircleIcon class="w-4 h-4 text-blue-600" />
                            </div>
                            <div>
                              <span class="font-medium text-gray-900">{{ sampling?.controller?.name }}</span>
                              <span class="text-gray-500 ml-1">membuat sampling</span>
                            </div>
                          </div>
                          <time class="text-gray-500">{{ formatDateTime(sampling?.timestamps?.created) }}</time>
                        </div>
                      </div>

                       <!-- Last Updated -->
                       <div 
                        class="px-4 py-3 flex flex-col gap-1"
                      >
                        <div class="flex items-center justify-between text-sm">
                          <div class="flex items-center gap-2">
                            <div class="w-8 h-8 rounded-full bg-yellow-50 flex items-center justify-center">
                              <PencilIcon class="w-4 h-4 text-yellow-600" />
                            </div>
                            <div>
                              <span class="font-medium text-gray-900">{{ sampling?.controller?.name }}</span>
                              <span class="text-gray-500 ml-1">memperbarui hasil</span>
                            </div>
                          </div>
                          <time class="text-gray-500">{{ formatDateTime(sampling?.timestamps?.updated) }}</time>
                        </div>
                      </div>

                      <!-- Validated -->
                      <div class="px-4 py-3 flex flex-col gap-1">
                        <div class="flex items-center justify-between text-sm">
                          <div class="flex items-center gap-2">
                            <div class="w-8 h-8 rounded-full bg-green-50 flex items-center justify-center">
                              <ClipboardDocumentCheckIcon class="w-4 h-4 text-green-600" />
                            </div>
                            <div>
                              <span class="font-medium text-gray-900">{{ sampling?.controller?.name }}</span>
                              <span class="text-gray-500 ml-1">memvalidasi sampling</span>
                            </div>
                          </div>
                          <time class="text-gray-500">{{ formatDateTime(sampling?.timestamps?.validated) }}</time>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Edit/Create Mode -->
              <div v-else class="space-y-6">
                <!-- Result Selection -->
                <div class="space-y-3">
                  <label class="text-sm font-medium text-gray-700 block">
                    Hasil Validasi <span class="text-red-500">*</span>
                  </label>
                  <div class="grid grid-cols-2 gap-3">
                    <button 
                      type="button"
                      class="px-4 py-3 rounded-lg border-2 flex items-center justify-center gap-2 transition-colors"
                      :class="result === 'pass' 
                        ? 'border-green-500 bg-green-50 text-green-700' 
                        : 'border-gray-200 hover:bg-gray-50'"
                      @click="result = 'pass'"
                    >
                      <CheckCircleIcon class="w-5 h-5" />
                      Lulus
                    </button>
                    <button
                      type="button" 
                      class="px-4 py-3 rounded-lg border-2 flex items-center justify-center gap-2 transition-colors"
                      :class="result === 'fail' 
                        ? 'border-red-500 bg-red-50 text-red-700' 
                        : 'border-gray-200 hover:bg-gray-50'"
                      @click="result = 'fail'"
                    >
                      <XCircleIcon class="w-5 h-5" />
                      Tidak Lulus
                    </button>
                  </div>
                </div>

                <!-- Notes Input -->
                <div class="space-y-2">
                  <label class="text-sm font-medium text-gray-700 flex items-center justify-between">
                    <span>Catatan <span class="text-red-500">*</span></span>
                    <span class="text-gray-400 text-xs">Minimal 10 karakter</span>
                  </label>
                  <textarea
                    v-model="notes"
                    rows="4"
                    class="w-full px-4 py-3 border rounded-lg resize-none focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    :class="notesError ? 'border-red-300' : 'border-gray-300'"
                    placeholder="Tambahkan catatan validasi..."
                  />
                  <p v-if="notesError" class="text-sm text-red-500">
                    {{ notesError }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Footer -->
            <div class="bg-gray-50 px-6 py-4 flex justify-end gap-3">
              <button
                type="button"
                class="px-4 py-2 border rounded-lg text-sm hover:bg-white transition-colors"
                @click="handleClose"
              >
                {{ isReadOnly ? 'Tutup' : 'Batal' }}
              </button>
              
              <button
                v-if="!isReadOnly"
                type="button"
                class="px-4 py-2 bg-blue-500 text-white rounded-lg text-sm hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center gap-2"
                :disabled="!isValid || loading"
                @click="handleValidate"
              >
                <span v-if="loading" class="animate-spin rounded-full h-4 w-4 border-2 border-white border-r-transparent" />
                {{ loading ? 'Menyimpan...' : (props.isEdit ? 'Perbarui' : 'Simpan') }}
              </button>
              
              <button
                v-else-if="isValidated"
                type="button"
                class="px-4 py-2 bg-blue-500 text-white rounded-lg text-sm hover:bg-blue-600 transition-colors"
                @click="switchToEdit"
              >
                Edit
              </button>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>


<script setup>
import { ref, computed, watch } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { 
  CheckCircleIcon, 
  XCircleIcon, 
  UserIcon, 
  ClockIcon, 
  UserCircleIcon,
  PlusCircleIcon,
  ClipboardDocumentCheckIcon,
  PencilIcon
} from '@heroicons/vue/24/outline'
import { toast } from 'vue-sonner'
import apiClient from '../../config/api'

// / Tambah prop isEdit
const props = defineProps({
  isOpen: Boolean,
  sampling: Object,
  isEdit: Boolean,
  onClose: Function,
  onValidated: Function
})

// Define emits
const emit = defineEmits(['update:isEdit', 'update:isOpen'])


const loading = ref(false)
const result = ref('')
const notes = ref('')

// Update switchToEdit method
const switchToEdit = () => {
  emit('update:isEdit', true)
  // Jika menggunakan v-model, pastikan parent component menyediakan v-model:isEdit
}

// Update onClose untuk reset state
const handleClose = () => {
  // Reset state
  result.value = ''
  notes.value = ''
  emit('update:isEdit', false)
  props.onClose()
}

// Computed properties
const isValidated = computed(() => props.sampling?.state === 'done')

const getEmployeeName = computed(() => {
  if (props.sampling?.sop?.is_sa) {
    return props.sampling?.employee?.service_advisor?.[0]?.name || '-'
  }
  return props.sampling?.employee?.mechanic?.[0]?.name || '-'
})

const handleValidate = async () => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/sop/sampling/validate', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        sampling_id: props.sampling.id,
        result: result.value,
        notes: notes.value
      }
    })

    if (response?.data?.result?.status === 'success') {
      toast.success('Sampling validated successfully')
      props.onValidated?.(response.data.result.data)
      props.onClose()
    }
  } catch (error) {
    console.error('Error validating sampling:', error)
    toast.error('Failed to validate sampling: ' + error.message)
  } finally {
    loading.value = false
  }
}

// Add new computed properties
const getResultClass = computed(() => ({
  'bg-green-100 text-green-700': result.value === 'pass',
  'bg-red-100 text-red-700': result.value === 'fail'
}))

const getResultIcon = computed(() => 
  result.value === 'pass' ? CheckCircleIcon : XCircleIcon
)
// Modify computed property
const isReadOnly = computed(() => 
  props.sampling?.state === 'done' && !props.isEdit
)

// Update title computation
const dialogTitle = computed(() => {
  if (props.isEdit) return 'Edit Validation'
  if (isValidated.value) return 'Validation Result'
  return 'Validate Sampling'
})

const formatDateTime = (timestamp) => {
  if (!timestamp) return '-'
  const date = new Date(timestamp)
  date.setHours(date.getHours() + 7) // Adjust untuk WIB
  
  return date.toLocaleString('id-ID', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}
// Add validation for notes
const notesError = computed(() => {
  if (!notes.value && result.value) return 'Catatan harus diisi'
  if (notes.value && notes.value.length < 10) return 'Catatan minimal 10 karakter'
  return ''
})

// Improve validation check
const isValid = computed(() => {
  return result.value && 
         notes.value?.trim()?.length >= 10 && 
         !notesError.value
})

const isUpdated = computed(() => {
  return props.sampling?.timestamps?.updated && 
         props.sampling?.timestamps?.updated !== props.sampling?.timestamps?.created &&
         props.sampling?.timestamps?.updated !== props.sampling?.timestamps?.validated
})

// Improved date formatting
const formatDate = (dateString) => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  date.setHours(date.getHours() + 7)
  
  const formattedDate = date.toLocaleDateString('id-ID', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
  
  return formattedDate
}

// Reset form or set existing values when dialog opens
watch(() => props.isOpen, (newVal) => {
  if (newVal) {
    if (isValidated.value) {
      result.value = props.sampling.result
      notes.value = props.sampling.notes
    } else {
      result.value = ''
      notes.value = ''
    }
  }
})
</script>