<!-- CreatePurchaseDialog.vue -->
<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" @close="handleClose" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
      </TransitionChild>

      <div class="fixed inset-0 flex items-center justify-center p-4">
        <TransitionChild as="template" 
           enter="duration-300 ease-out" 
           enter-from="opacity-0 scale-95"
           enter-to="opacity-100 scale-100"
        >
          <DialogPanel class="w-full max-w-2xl bg-white rounded-2xl p-6 shadow-xl">
            <!-- Header -->
            <div class="flex justify-between items-center mb-6">
              <DialogTitle class="text-xl font-semibold text-gray-900">
                Buat Pembelian Part untuk Order #{{ order?.name }}
              </DialogTitle>
              <div class="flex items-center gap-2">
                <span class="w-2 h-2 rounded-full bg-yellow-400"></span>
                <span class="text-sm text-gray-600">Menunggu Part</span>
              </div>
            </div>

            <!-- Order Info -->
            <div class="bg-gray-50 rounded-lg p-4 mb-6">
              <div class="grid grid-cols-2 gap-6">
                <div>
                  <h3 class="text-sm font-medium text-gray-500">Detail Order</h3>
                  <p class="mt-1 text-lg font-semibold">#{{ order?.name }}</p>
                  <p class="text-sm text-gray-600">
                    Dibuat pada {{ formatDate(order?.timestamps?.reception) }}
                  </p>
                </div>
                <div>
                  <h3 class="text-sm font-medium text-gray-500">Customer & Kendaraan</h3>
                  <p class="mt-1 font-medium">{{ order?.customer?.name }}</p>
                  <p class="text-sm text-gray-600">
                    {{ order?.car?.brand }} {{ order?.car?.type }} - 
                    <span class="font-medium">{{ order?.car?.plate }}</span>
                  </p>
                </div>
              </div>
            </div>

            <!-- Form -->
            <form @submit.prevent="handleSubmit" class="space-y-6">
              <!-- Partman Selection -->
              <div class="space-y-2">
                <label class="block text-sm font-medium text-gray-700">
                  Pilih Partman
                </label>
                <div class="relative">
                  <select
                    v-model="form.partman_id"
                    :disabled="loading"
                    class="w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500 pr-10"
                    :class="{'border-red-300': errors.partman_id}"
                    required
                  >
                    <option value="">{{ loading ? 'Memuat data...' : 'Pilih Partman' }}</option>
                    <option 
                      v-for="partman in partmen" 
                      :key="partman.id"
                      :value="partman.id"
                    >
                      {{ partman.name }} ({{ partman.job_title }})
                    </option>
                  </select>
                  <!-- Loading indicator -->
                  <div v-if="loading" class="absolute right-3 top-2">
                    <div class="animate-spin h-5 w-5 border-2 border-blue-500 border-t-transparent rounded-full"></div>
                  </div>
                </div>
                <p v-if="errors.partman_id" class="text-sm text-red-600">
                  {{ errors.partman_id }}
                </p>
              </div>

              <!-- Review Type -->
              <!-- Review Type -->
              <div class="space-y-2">
                <label class="block text-sm font-medium text-gray-700">
                  Jenis Review
                </label>
                <div class="grid grid-cols-3 gap-4">
                  <!-- Margin -->
                  <label class="relative flex cursor-pointer rounded-lg border p-4"
                        :class="form.review_type === 'margin' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'"
                  >
                    <input type="radio" 
                          v-model="form.review_type"
                          value="margin"
                          class="sr-only"
                    >
                    <span class="flex flex-col">
                      <span class="text-sm font-medium text-gray-900">Margin</span>
                      <span class="mt-1 text-xs text-gray-500">
                        Review margin harga part
                      </span>
                    </span>
                  </label>
                  
                  <!-- Durasi Estimasi -->
                  <label class="relative flex cursor-pointer rounded-lg border p-4"
                        :class="form.review_type === 'duration_estimated' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'"
                  >
                    <input type="radio"
                          v-model="form.review_type"
                          value="duration_estimated"
                          class="sr-only"
                    >
                    <span class="flex flex-col">
                      <span class="text-sm font-medium text-gray-900">Durasi Estimasi</span>
                      <span class="mt-1 text-xs text-gray-500">
                        Estimasi waktu pengerjaan
                      </span>
                    </span>
                  </label>

                  <!-- Kondisi Partman -->
                  <label class="relative flex cursor-pointer rounded-lg border p-4"
                        :class="form.review_type === 'partman_condition' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'"
                  >
                    <input type="radio"
                          v-model="form.review_type"
                          value="partman_condition"
                          class="sr-only"
                    >
                    <span class="flex flex-col">
                      <span class="text-sm font-medium text-gray-900">Kondisi Partman</span>
                      <span class="mt-1 text-xs text-gray-500">
                        Ketersediaan partman
                      </span>
                    </span>
                  </label>
                </div>
              </div>

              <!-- Form ditambahkan section Estimasi setelah Review Type -->
              <div class="space-y-6">
                <!-- Estimasi Time -->
                <div class="grid grid-cols-2 gap-4">
                  <!-- Estimated Departure -->
                  <div class="space-y-2">
                    <label class="block text-sm font-medium text-gray-700">
                      Estimasi Berangkat
                    </label>
                    <input
                      type="time"
                      v-model="form.estimated_departure"
                      class="w-full px-3 py-2 resize-none rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 placeholder:text-gray-400 text-sm"
                      :class="{'border-red-300': errors.estimated_departure}"
                      required
                    />
                    <p v-if="errors.estimated_departure" class="text-sm text-red-600">
                      {{ errors.estimated_departure }}
                    </p>
                  </div>

                  <!-- Estimated Return -->
                  <div class="space-y-2">
                    <label class="block text-sm font-medium text-gray-700">
                      Estimasi Kembali
                    </label>
                    <input
                      type="time"
                      v-model="form.estimated_return"
                      class="w-full px-3 py-2 resize-none rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 placeholder:text-gray-400 text-sm"
                      :class="{'border-red-300': errors.estimated_return}"
                      required
                    />
                    <p v-if="errors.estimated_return" class="text-sm text-red-600">
                      {{ errors.estimated_return }}
                    </p>
                  </div>
                </div>
                
                <!-- Estimated Duration (readonly) -->
                <div v-if="estimatedDuration" class="bg-blue-50 p-4 rounded-lg">
                  <div class="flex items-center gap-2">
                    <ClockIcon class="h-5 w-5 text-blue-500" />
                    <span class="font-medium text-blue-700">
                      Estimasi Durasi: {{ estimatedDuration }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Notes -->
              <div class="space-y-2">
                <label class="block text-sm font-medium text-gray-700">
                  Catatan
                </label>
                <div class="relative mt-1">
                  <textarea
                    v-model="form.notes"
                    rows="3"
                    maxlength="500"
                    class="w-full px-3 py-2 resize-none rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 placeholder:text-gray-400 text-sm"
                    placeholder="Tambahkan instruksi khusus atau persyaratan..."
                  />
                  <div class="absolute bottom-2 right-2 px-2 text-xs text-gray-400 bg-white">
                    {{ form.notes.length }}/500
                  </div>
                </div>
                <p class="text-xs text-gray-500 mt-1">
                  Tambahkan catatan atau instruksi khusus untuk pembelian part
                </p>
              </div>

              <!-- Actions -->
              <div class="flex justify-end gap-3 pt-6 border-t">
                <button
                  type="button"
                  @click="handleClose"
                  class="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
                >
                  Batal
                </button>
                <button
                  type="submit"
                  :disabled="loading || !form.partman_id"
                  class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
                >
                  <span v-if="loading" class="flex items-center gap-2">
                    <div class="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                    Memproses...
                  </span>
                  <span v-else>Buat Pembelian</span>
                </button>
              </div>
            </form>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionRoot,
  TransitionChild
} from '@headlessui/vue'
import { toast } from 'vue-sonner'
import apiClient from '@/config/api' // Tambahkan ini
import { ClockIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  show: Boolean,
  order: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['close', 'created'])

// State
const loading = ref(false)
const partmen = ref([])
const errors = ref({})

const form = ref({
  partman_id: '',
  review_type: '',
  notes: '',
  estimated_departure: '',
  estimated_return: ''
})

// Add computed for estimated duration
const estimatedDuration = computed(() => {
  if (!form.value.estimated_departure || !form.value.estimated_return) return null
  
  const [departHours, departMinutes] = form.value.estimated_departure.split(':').map(Number)
  const [returnHours, returnMinutes] = form.value.estimated_return.split(':').map(Number)
  
  let durationMinutes = (returnHours * 60 + returnMinutes) - (departHours * 60 + departMinutes)
  
  // Handle if return time is on next day
  if (durationMinutes < 0) {
    durationMinutes += 24 * 60
  }
  
  const hours = Math.floor(durationMinutes / 60)
  const minutes = durationMinutes % 60
  
  return `${hours}j ${minutes}m`
})

// Di dalam <script setup>
const fetchPartmen = async () => {
  try {
    loading.value = true;
    const response = await apiClient.post('/web/employees', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        department_id: '8', // ID department Part Support
        limit: 100
      }
    });

    if (response?.data?.result?.status === 'success') {
      // Map data partman
      partmen.value = response.data.result.data.rows
        .filter(emp => emp.active && emp.job_title.toLowerCase().includes('part'))
        .map(emp => ({
          id: emp.id,
          name: emp.name,
          job_title: emp.job_title
        }));
    }
  } catch (error) {
    toast.error('Gagal memuat data partman');
    console.error('Error:', error);
  } finally {
    loading.value = false;
  }
};

// Update submit handler
const handleSubmit = async () => {
  try {
    if (!form.value.partman_id) {
      errors.value.partman_id = 'Please select a partman'
      return
    }

    if (!form.value.estimated_departure || !form.value.estimated_return) {
      errors.value.estimated_departure = !form.value.estimated_departure ? 'Required' : ''
      errors.value.estimated_return = !form.value.estimated_return ? 'Required' : ''
      return
    }

    loading.value = true
    errors.value = {}

    const response = await apiClient.post('/web/part-purchase/create', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        sale_order_id: props.order.id,
        partman_id: parseInt(form.value.partman_id),
        review_type: form.value.review_type,
        notes: form.value.notes,
        estimated_departure: form.value.estimated_departure,
        estimated_return: form.value.estimated_return
      }
    })

    if (response?.data?.result?.status === 'success') {
      toast.success('Purchase created successfully')
      emit('created', response.data.result.data)
      handleClose()
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to create purchase')
    }
  } catch (error) {
    if (error.response?.data?.error?.data?.validation_errors) {
      errors.value = error.response.data.error.data.validation_errors
    } else {
      toast.error(error.message || 'Failed to create purchase')
    }
  } finally {
    loading.value = false
  }
}

const formatDate = (dateString) => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  return new Intl.DateTimeFormat('id-ID', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date)
}

const resetForm = () => {
  form.value = {
    partman_id: '',
    review_type: '',
    notes: '',
    estimated_departure: '',
    estimated_return: ''
  }
  errors.value = {}
}

const handleClose = () => {
  resetForm();
  emit('close');
};

// Tambahkan watch untuk show prop
watch(() => props.show, (newVal) => {
  if (newVal) {
    resetForm();
    fetchPartmen();
  }
});

onMounted(() => {
  fetchPartmen()
})
</script>