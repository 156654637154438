<template>
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Header -->
      <div class="flex flex-col md:flex-row justify-between items-center mb-8">
        <h1 class="text-xl font-semibold">Part Purchase Statistics</h1>
        
        <!-- Date Range Selector -->
        <div class="flex items-center gap-3">
          <select 
            v-model="dateRange"
            class="rounded-lg border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
            @change="fetchStats"
          >
            <option value="today">Today</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="custom">Custom Range</option>
          </select>
          
          <div v-if="dateRange === 'custom'" class="flex gap-2">
            <input 
              type="date" 
              v-model="startDate"
              class="rounded-lg border-gray-300 text-sm"
            >
            <input 
              type="date" 
              v-model="endDate"
              class="rounded-lg border-gray-300 text-sm"
            >
            <button 
              @click="fetchStats"
              class="px-3 py-1.5 bg-blue-500 text-white rounded-lg text-sm hover:bg-blue-600"
            >
              Apply
            </button>
          </div>
        </div>
      </div>

      <!-- Stats Cards -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div class="bg-blue-50 rounded-xl p-6">
          <div class="flex items-center justify-between">
            <div>
              <p class="text-blue-600 text-sm font-medium">Total Pembelanjaan</p>
              <p class="text-2xl font-bold text-blue-900 mt-1">
                {{ stats?.summary?.total_purchases || 0 }}
              </p>
            </div>
            <div class="bg-blue-100 p-3 rounded-lg">
              <ShoppingBagIcon class="h-6 w-6 text-blue-600" />
            </div>
          </div>
          <p class="text-sm text-blue-600/80 mt-2">
            {{ stats?.summary?.departed_purchases || 0 }} dalam proses
          </p>
        </div>

        <div class="bg-green-50 rounded-xl p-6">
          <div class="flex items-center justify-between">
            <div>
              <p class="text-green-600 text-sm font-medium">Total Selesai</p>
              <p class="text-2xl font-bold text-green-900 mt-1">
                {{ stats?.summary?.completed_purchases || 0 }}
              </p>
            </div>
            <div class="bg-green-100 p-3 rounded-lg">
              <CheckCircleIcon class="h-6 w-6 text-green-600" />
            </div>
          </div>
          <p class="text-sm text-green-600/80 mt-2">
            {{ (stats?.summary?.completion_rate || 0).toFixed(1) }}% completion rate
          </p>
        </div>

        <div class="bg-yellow-50 rounded-xl p-6">
          <div class="flex items-center justify-between">
            <div>
              <p class="text-yellow-600 text-sm font-medium">Rata-rata durasi</p>
              <p class="text-2xl font-bold text-yellow-900 mt-1">
                {{ stats?.summary?.average_duration_display || '0j 0m' }}
              </p>
            </div>
            <div class="bg-yellow-100 p-3 rounded-lg">
              <ClockIcon class="h-6 w-6 text-yellow-600" />
            </div>
          </div>
        </div>

        <div class="bg-red-50 rounded-xl p-6">
          <div class="flex items-center justify-between">
            <div>
              <p class="text-red-600 text-sm font-medium">Dibatalkan</p>
              <p class="text-2xl font-bold text-red-900 mt-1">
                {{ stats?.summary?.cancelled_purchases || 0 }}
              </p>
            </div>
            <div class="bg-red-100 p-3 rounded-lg">
              <XCircleIcon class="h-6 w-6 text-red-600" />
            </div>
          </div>
        </div>
      </div>

      <!-- Charts -->
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <!-- Status Distribution -->
        <div class="bg-white rounded-xl border p-6">
          <h3 class="text-lg font-medium mb-4">Status Distribution</h3>
          <apexchart
            type="donut"
            height="300"
            :options="donutOptions"
            :series="donutSeries"
          />
        </div>

        <!-- Partman Performance -->
        <div class="bg-white rounded-xl border p-6">
          <h3 class="text-lg font-medium mb-4">Partman Performance</h3>
          <apexchart
            type="bar"
            height="300"
            :options="barOptions"
            :series="barSeries"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { 
  ShoppingBagIcon, 
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon
} from '@heroicons/vue/24/outline'
import apiClient from '@/config/api'
import VueApexCharts from 'vue3-apexcharts'

// State
const dateRange = ref('today')
const startDate = ref('')
const endDate = ref('')
const stats = ref(null)
const loading = ref(false)

// Chart Options
const donutOptions = computed(() => ({
  chart: {
    type: 'donut'
  },
  colors: ['#3B82F6', '#22C55E', '#EF4444'],
  labels: ['En Route', 'Completed', 'Cancelled'],
  legend: {
    position: 'bottom'
  },
  plotOptions: {
    pie: {
      donut: {
        size: '70%'
      }
    }
  }
}))

const donutSeries = computed(() => {
  if (!stats.value?.summary) return []
  return [
    stats.value.summary.departed_purchases,
    stats.value.summary.completed_purchases,
    stats.value.summary.cancelled_purchases
  ]
})

const barOptions = computed(() => ({
  chart: {
    type: 'bar'
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: true,
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#6366F1'],
  xaxis: {
    categories: stats.value?.partman_performance?.map(p => p.partman_name) || [],
    title: {
      text: 'Average Duration (Hours)'
    }
  },
  yaxis: {
    title: {
      text: 'Partman'
    }
  }
}))

const barSeries = computed(() => [{
  name: 'Average Duration',
  data: stats.value?.partman_performance?.map(p => p.avg_duration) || []
}])

// Methods
const fetchStats = async () => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/part-purchase/statistics', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        date_range: dateRange.value,
        start_date: dateRange.value === 'custom' ? startDate.value : null,
        end_date: dateRange.value === 'custom' ? endDate.value : null
      }
    })

    if (response?.data?.result?.status === 'success') {
      stats.value = response.data.result.data
    }
  } catch (error) {
    console.error('Error fetching stats:', error)
  } finally {
    loading.value = false
  }
}

// Lifecycle
onMounted(() => {
  fetchStats()
})
</script>