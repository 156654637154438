<template>
  <Toaster 
    position="top-right"
    :duration="3000"
    :richColors="true"
  />
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Memuat data...</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="space-y-6">
        <!-- Header -->
        <div class="flex flex-col md:flex-row justify-between items-center mb-6">
          <div class="flex items-center space-x-4">
            <h1 class="text-2xl font-semibold text-gray-900">Pilih Order untuk Sampling SOP</h1>
            <div v-if="selectedOrders.length > 0" 
                 class="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium">
              {{ selectedOrders.length }} selected
            </div>
          </div>
          
          <!-- Bulk Action Button -->
          <button
            v-if="selectedOrders.length > 0"
            @click="openBulkSamplingDialog"
            class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
          >
            Create Sampling for Selected Orders
          </button>
        </div>

        <!-- Search and Filter Section -->
        <div class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
          <!-- Search Input -->
          <div class="relative w-full md:w-80">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search order number, plate number..."
              class="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              @input="handleSearch"
            >
            <div class="absolute left-3 top-2.5">
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
            </div>
          </div>

          <!-- Ganti role filter select dengan ini -->
          <div class="flex rounded-lg border overflow-hidden">
            <button
              @click="selectedRole = ''"
              class="px-4 py-2 text-sm"
              :class="selectedRole === '' 
                ? 'bg-blue-50 text-blue-600 font-medium' 
                : 'hover:bg-gray-50'"
            >
              Semua
            </button>
            <button
              @click="selectedRole = true"
              class="px-4 py-2 text-sm border-l"
              :class="selectedRole === true 
                ? 'bg-blue-50 text-blue-600 font-medium' 
                : 'hover:bg-gray-50'"
            >
              Service Advisor
            </button>
            <button
              @click="selectedRole = false"
              class="px-4 py-2 text-sm border-l"
              :class="selectedRole === false 
                ? 'bg-blue-50 text-blue-600 font-medium' 
                : 'hover:bg-gray-50'"
            >
              Mekanik
            </button>
          </div>
        </div>

        <!-- Table Section -->
        <div class="overflow-x-auto rounded-lg border">
          <table class="w-full">
            <thead class="bg-gray-50">
                <tr>
                <th class="text-left py-3 px-4 font-medium text-gray-700">No. Order</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Tanggal</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Info Mobil</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Service Advisor</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Mekanik</th>
                <th class="text-center py-3 px-4 font-medium text-gray-700">Jumlah Sampling</th>
                <th class="text-center py-3 px-4 font-medium text-gray-700">Aksi</th>
                </tr>
            </thead>
            <tbody>
              <tr 
                v-for="order in orders" 
                :key="order.id"
                class="border-t hover:bg-gray-50"
              >
                <td class="py-3 px-4 font-medium">{{ order.name }}</td>
                <td class="py-3 px-4">{{ formatDate(order.date) }}</td>
                <td class="py-3 px-4">
                  <div class="space-y-1">
                    <div class="font-medium">{{ order.car_info.plate }}</div>
                    <div class="text-sm text-gray-500">
                      {{ order.car_info.brand }} {{ order.car_info.type }}
                    </div>
                  </div>
                </td>
                <td class="py-3 px-4">
                  <div v-for="sa in order.employee_info.service_advisor" :key="sa.id" 
                       class="flex items-center space-x-2 text-sm">
                    <span class="font-medium">{{ sa.name }}</span>
                    <span v-if="sa.sampling_count > 0" 
                          class="px-2 py-0.5 bg-blue-100 text-blue-800 rounded-full text-xs">
                      {{ sa.sampling_count }} samples
                    </span>
                  </div>
                </td>
                <td class="py-3 px-4">
                  <div v-for="mech in order.employee_info.mechanic" :key="mech.id"
                       class="flex items-center space-x-2 text-sm">
                    <span class="font-medium">{{ mech.name }}</span>
                    <span v-if="mech.sampling_count > 0"
                          class="px-2 py-0.5 bg-blue-100 text-blue-800 rounded-full text-xs">
                      {{ mech.sampling_count }} samples
                    </span>
                  </div>
                </td>
                <td class="py-3 px-4 justify-center">
                  <div class="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-sm justify-center text-center w-fit">
                    {{ order.sampling_count }}
                  </div>
                </td>
                <td class="py-3 px-4">
                  <div class="flex justify-center">
                    <div class="relative flex rounded-lg overflow-hidden">
                      <!-- View Existing -->
                      <button
                        v-if="order.sampling_count > 0"
                        @click="viewSamplingDetails(order)"
                        class="px-4 py-2 bg-green-500 text-white hover:bg-green-600 transition-colors flex items-center space-x-2"
                      >
                        <EyeIcon class="w-4 h-4" />
                        <span>Lihat Detil</span>
                        <span class="px-2 py-0.5 bg-green-600 rounded-full text-xs">
                          {{ order.sampling_count }}
                        </span>
                      </button>
                
                      <!-- Add More -->
                      <button
                        v-if="order.sampling_count > 0"
                        @click="openSamplingDialog(order)"
                        class="px-3 py-2 bg-green-500 text-white hover:bg-green-600 border-l border-green-600"
                      >
                        <PlusIcon class="w-4 h-4" />
                      </button>
                
                      <!-- Create New -->
                      <button
                        v-else
                        @click="openSamplingDialog(order)"
                        class="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors flex items-center space-x-2"
                      >
                        <ClipboardDocumentListIcon class="w-4 h-4" />
                        <span>Pilih Sampling</span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination section -->
        <div 
          v-if="pagination"
          class="flex flex-col md:flex-row justify-between items-center gap-4 py-4"
          >
          <div class="flex items-center gap-4">
            <span class="text-sm text-gray-600">
              Menampilkan {{ startNumber }} sampai {{ endNumber }} 
              dari {{ pagination.total_items }} data
            </span>
            
            <select
              v-model="itemsPerPage"
              @change="handleItemsPerPageChange"
              class="border rounded-lg px-2 py-1 text-sm"
            >
              <option :value="10">10 per halaman</option>
              <option :value="20">20 per halaman</option>
              <option :value="50">50 per halaman</option>
              <option :value="100">100 per halaman</option>
            </select>
          </div>

          <div class="flex items-center gap-2">
            <button
              @click="handlePageChange(pagination.current_page - 1)"
              :disabled="!pagination.has_previous"
              class="px-3 py-1 rounded-lg border disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Sebelumnya
            </button>
            
            <template v-for="page in pageNumbers" :key="page">
              <span v-if="page === '...'" class="px-3 py-1">...</span>
              <button
                v-else
                @click="handlePageChange(page)"
                class="px-3 py-1 rounded-lg border"
                :class="pagination.current_page === page 
                  ? 'bg-blue-50 border-blue-500 text-blue-600' 
                  : 'hover:bg-gray-50'"
              >
                {{ page }}
              </button>
            </template>
            
            <button
              @click="handlePageChange(pagination.current_page + 1)"
              :disabled="!pagination.has_next"
              class="px-3 py-1 rounded-lg border disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Selanjutnya
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Create Sampling Dialog -->
    <TransitionRoot appear :show="showSamplingDialog" as="template">
      <Dialog as="div" @close="closeSamplingDialog" class="relative z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
        </TransitionChild>

        <div class="fixed inset-0 flex items-center justify-center p-4">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle class="text-lg font-medium leading-6 text-gray-900 mb-4">
                Create New Sampling for Order #{{ selectedOrder?.name }}
              </DialogTitle>
              
              <!-- Order Info Summary -->
              <div class="mb-6 bg-gray-50 p-4 rounded-lg">
                <div class="grid grid-cols-2 gap-4">
                  <div>
                    <div class="text-sm text-gray-600">Car Info</div>
                    <div class="font-medium">{{ selectedOrder?.car_info?.plate }}</div>
                    <div class="text-sm">{{ selectedOrder?.car_info?.brand }} {{ selectedOrder?.car_info?.type }}</div>
                  </div>
                  <div>
                    <div class="text-sm text-gray-600">Service Date</div>
                    <div class="font-medium">{{ formatDate(selectedOrder?.date) }}</div>
                  </div>
                </div>
              </div>

              <form @submit.prevent="handleCreateSampling" class="space-y-4">
                <!-- List of SOPs with Checkbox -->
                <div class="space-y-4">
                  <div class="flex justify-between items-center">
                    <h4 class="font-medium text-gray-900">Select SOPs for Sampling</h4>
                    <div class="flex items-center space-x-4">
                       <!-- Search input for SOPs -->
                      <div class="relative w-64">
                        <input
                          v-model="sopSearchQuery"
                          type="text"
                          placeholder="Search SOP..."
                          class="w-full pl-8 pr-3 py-1.5 text-sm border rounded-lg"
                        >
                        <MagnifyingGlassIcon class="absolute left-2.5 top-2 h-4 w-4 text-gray-400" />
                      </div>
                      <button
                        type="button"
                        class="text-sm text-blue-600 hover:text-blue-800"
                        @click="toggleAllSOPs"
                      >
                        {{ isAllSOPsSelected ? 'Deselect All' : 'Select All' }}
                      </button>
                      <select
                        v-model="sopFilter"
                        class="text-sm border rounded-lg px-2 py-1"
                        @change="filterSOPs"
                      >
                        <option value="">All Types</option>
                        <option :value="true">Service Advisor</option>
                        <option :value="false">Mechanic</option>
                      </select>
                    </div>
                  </div>

                  <div class="max-h-64 overflow-y-auto border rounded-lg divide-y">
                    <div 
                      v-for="sop in filteredSOPs" 
                      :key="sop.id"
                      class="p-3 hover:bg-gray-50 flex items-start space-x-3"
                    >
                      <div class="pt-1">
                        <input
                          type="checkbox"
                          v-model="selectedSOPs"
                          :value="sop.id"
                          :id="'sop-' + sop.id"
                          class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        >
                      </div>
                      <div class="flex-1">
                        <label :for="'sop-' + sop.id" class="block cursor-pointer">
                          <div class="font-medium text-gray-900">{{ sop.code }} - {{ sop.name }}</div>
                          <div class="text-sm text-gray-500">{{ sop.description }}</div>
                          <div class="mt-1">
                            <span 
                              class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium"
                              :class="sop.is_sa ? 'bg-purple-100 text-purple-800' : 'bg-green-100 text-green-800'"
                            >
                              {{ sop.is_sa ? 'Service Advisor' : 'Mechanic' }}
                            </span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Notes input moved below SOP selection -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    Notes
                  </label>
                  <textarea
                    v-model="samplingForm.notes"
                    rows="3"
                    class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Add notes for this sampling..."
                  ></textarea>
                </div>

                <!-- Action Buttons -->
                <div class="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    @click="closeSamplingDialog"
                    class="px-4 py-2 border rounded-lg hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    :disabled="loading || selectedSOPs.length === 0"
                  >
                    Create Sampling
                  </button>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <SamplingDetailsDialog
    :is-open="showSamplingDetailsDialog"
    :selected-order="selectedOrder"
    :on-close="() => showSamplingDetailsDialog = false"
  />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { 
  Dialog, 
  DialogPanel, 
  DialogTitle, 
  TransitionRoot, 
  TransitionChild,
  Switch 
} from '@headlessui/vue'
import { Toaster, toast } from 'vue-sonner'
import apiClient from '../../config/api'
import debounce from 'lodash/debounce'
import SamplingDetailsDialog from './SamplingDetailModal.vue'

import { 
  ClipboardDocumentListIcon, 
  EyeIcon, 
  PlusIcon,
  MagnifyingGlassIcon 
} from '@heroicons/vue/24/outline'


// State
const loading = ref(false)
const orders = ref([])
const pagination = ref(null)
const searchQuery = ref('')
const selectedRole = ref('')
const showSamplingDialog = ref(false)
const selectedOrders = ref([])
// # Tambahan/modifikasi state
const selectedOrder = ref(null)
const selectedSOPs = ref([])
const sopFilter = ref('')
const availableSOPs = ref([])

// Add to existing script
const sopSearchQuery = ref('')

const filteredSOPs = computed(() => {
  let filtered = availableSOPs.value
  
  // Filter by type (SA/Mechanic)
  if (sopFilter.value !== '') {
    filtered = filtered.filter(sop => sop.is_sa === sopFilter.value)
  }
  
  // Filter by search query
  if (sopSearchQuery.value) {
    const query = sopSearchQuery.value.toLowerCase()
    filtered = filtered.filter(sop => 
      sop.name.toLowerCase().includes(query) || 
      sop.code.toLowerCase().includes(query) ||
      sop.description?.toLowerCase().includes(query)
    )
  }
  
  return filtered
})

const isAllSOPsSelected = computed(() => {
  return filteredSOPs.value.length > 0 && 
         filteredSOPs.value.every(sop => selectedSOPs.value.includes(sop.id))
})

const showSamplingDetailsDialog = ref(false)

const viewSamplingDetails = (order) => {
  selectedOrder.value = order
  showSamplingDetailsDialog.value = true
}

// Methods
const toggleAllSOPs = () => {
  if (isAllSOPsSelected.value) {
    selectedSOPs.value = []
  } else {
    selectedSOPs.value = filteredSOPs.value.map(sop => sop.id)
  }
}

const filterSOPs = () => {
  // Reset selection when filter changes
  selectedSOPs.value = []
}

const fetchExistingSamplings = async (orderId) => {
  try {
    const response = await apiClient.post('/web/sop/sampling/list', {
      jsonrpc: "2.0", 
      method: "call",
      params: {
        page: 1,
        limit: 25,
        search: selectedOrder.value.name,
        month: new Date(selectedOrder.value.date).toISOString().slice(0, 7),
        is_sa: null
      }
    })

    if (response?.data?.result?.status === 'success') {
      const orderSamplings = response.data.result.data.rows.filter(
        sampling => sampling.sale_order.id === orderId
      )
      selectedSOPs.value = orderSamplings.map(sampling => sampling.sop.id)
    }
  } catch (error) {
    console.error('Error fetching existing samplings:', error)
  }
}

const openSamplingDialog = async (order) => {
  selectedOrder.value = order
  samplingForm.value.notes = ''
  
  if (order.sampling_count > 0) {
    await fetchExistingSamplings(order.id)
  } else {
    selectedSOPs.value = []
  }
  
  showSamplingDialog.value = true
}

const closeSamplingDialog = () => {
  showSamplingDialog.value = false
  selectedOrder.value = null
  selectedSOPs.value = []
  samplingForm.value.notes = ''
}


// Form state
const samplingForm = ref({
  sop_id: '',
  notes: ''
})

// Computed
const startNumber = computed(() => {
  if (!pagination.value) return 0
  return ((pagination.value.current_page - 1) * pagination.value.items_per_page) + 1
})

const endNumber = computed(() => {
  if (!pagination.value) return 0
  return Math.min(
    startNumber.value + pagination.value.items_per_page - 1,
    pagination.value.total_items
  )
})

const isAllSelected = computed(() => {
  return orders.value.length > 0 && selectedOrders.value.length === orders.value.length
})

// Methods
const fetchData = async (params = {}) => {
  try {
    loading.value = true
    const payload = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: params.page || 1,
        limit: params.limit || itemsPerPage.value,
        search: searchQuery.value,
        is_sa: selectedRole.value === '' ? null : selectedRole.value
      }
    }

    const response = await apiClient.post('/web/sop/sampling/available-orders', payload)
    
    if (response?.data?.result?.status === 'success') {
      const { rows: orderRows, pagination: paginationData } = response.data.result.data
      orders.value = orderRows.map(order => ({
        ...order,
        isSelected: selectedOrders.value.includes(order.id)
      }))
      pagination.value = paginationData
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to fetch data')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    toast.error('Gagal memuat data: ' + error.message)
  } finally {
    loading.value = false
  }
}

const fetchSOPs = async () => {
  try {
    const response = await apiClient.post('/web/sop/master/list', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: 1,
        limit: 100,  // Assuming we want to load all SOPs
        is_sa: selectedRole.value === '' ? null : selectedRole.value
      }
    })
    
    if (response?.data?.result?.status === 'success') {
      availableSOPs.value = response.data.result.data.rows
    }
  } catch (error) {
    console.error('Error fetching SOPs:', error)
    toast.error('Gagal memuat daftar SOP')
  }
}

const handleSearch = debounce(() => {
  fetchData({ page: 1 })
}, 300)

const handleFilterChange = () => {
  fetchData({ page: 1 })
  fetchSOPs() // Refresh SOP list when role changes
}

const handlePageChange = (page) => {
  fetchData({ page })
}

const toggleAllSelection = (event) => {
  if (event.target.checked) {
    selectedOrders.value = orders.value.map(order => order.id)
  } else {
    selectedOrders.value = []
  }
}

// Modifikasi quick action di table
const handleQuickSelect = async (order) => {
  selectedOrder.value = order
  await fetchSOPs() // Make sure SOPs are loaded
  openSamplingDialog(order)
}


const openBulkSamplingDialog = () => {
  if (selectedOrders.value.length === 0) {
    toast.error('Please select at least one order')
    return
  }
  
  samplingForm.value = {
    sop_id: '',
    notes: ''
  }
  showSamplingDialog.value = true
}

const handleCreateSampling = async () => {
  if (!selectedOrder.value || selectedSOPs.value.length === 0) return
  
  try {
    loading.value = true
    const response = await apiClient.post('/web/sop/sampling/bulk-create', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        sale_order_id: selectedOrder.value.id,
        samplings: selectedSOPs.value.map(sopId => ({
          sop_id: sopId,
          notes: samplingForm.value.notes
        }))
      }
    })

    if (response?.data?.result?.status === 'success') {
      toast.success('Sampling created successfully')
      closeSamplingDialog()
      fetchData({ page: pagination.value?.current_page || 1 })
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to create sampling')
    }
  } catch (error) {
    console.error('Error creating sampling:', error)
    toast.error('Failed to create sampling: ' + error.message)
  } finally {
    loading.value = false
  }
}

// State untuk pagination
const itemsPerPage = ref(20)
const pageNumbers = computed(() => {
  if (!pagination.value) return []
  
  const totalPages = Math.ceil(pagination.value.total_items / itemsPerPage.value)
  const current = pagination.value.current_page
  const delta = 2 // Jumlah halaman yang ditampilkan sebelum dan sesudah halaman saat ini
  const pages = []
  
  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 || // Halaman pertama
      i === totalPages || // Halaman terakhir
      (i >= current - delta && i <= current + delta) // Halaman sekitar current
    ) {
      pages.push(i)
    } else if (pages[pages.length - 1] !== '...') {
      pages.push('...')
    }
  }
  
  return pages
})

// Method untuk handling perubahan items per page
const handleItemsPerPageChange = () => {
  fetchData({ page: 1, limit: itemsPerPage.value })
}


const formatDate = (dateString) => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  // Tambah 7 jam untuk WIB
  date.setHours(date.getHours() + 7)
  
  return date.toLocaleDateString('id-ID', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

// Lifecycle hooks
onMounted(() => {
  fetchData()
  fetchSOPs()
})
</script>