<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" @close="close" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
              <!-- Header -->
              <div class="flex items-center space-x-3 mb-6">
                <div 
                  class="p-2 rounded-lg"
                  :class="{
                    'bg-blue-50': type === 'depart',
                    'bg-green-50': type === 'return',
                    'bg-red-50': type === 'cancel'
                  }"
                >
                  <component 
                    :is="getIcon"
                    class="h-6 w-6"
                    :class="{
                      'text-blue-600': type === 'depart',
                      'text-green-600': type === 'return',
                      'text-red-600': type === 'cancel'
                    }"
                  />
                </div>
                <DialogTitle as="h3" class="text-lg font-medium text-gray-900">
                  {{ title }}
                </DialogTitle>
              </div>

              <div class="space-y-4">
                <!-- Time Input for Depart/Return -->
                <div v-if="['depart', 'return'].includes(type)">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ type === 'depart' ? 'Departure' : 'Return' }} Time
                  </label>
                  <input 
                    type="time" 
                    v-model="formData.time"
                    class="w-full px-3 py-2 resize-none rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 placeholder:text-gray-400 text-sm"
                  >
                </div>

                <!-- Notes -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    Notes
                  </label>
                  <textarea 
                    v-model="formData.notes"
                    rows="3"
                    class="w-full px-3 py-2 resize-none rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 placeholder:text-gray-400 text-sm"
                    :placeholder="getPlaceholder"
                  ></textarea>
                </div>

                <!-- Action Buttons -->
                <div class="flex justify-end space-x-3 mt-6">
                  <button
                    type="button"
                    class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
                    @click="close"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="px-4 py-2 text-sm font-medium text-white rounded-md"
                    :class="{
                      'bg-blue-600 hover:bg-blue-700': type === 'depart',
                      'bg-green-600 hover:bg-green-700': type === 'return',
                      'bg-red-600 hover:bg-red-700': type === 'cancel'
                    }"
                    @click="handleSubmit"
                  >
                    {{ getSubmitText }}
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { TruckIcon, ArrowPathIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  show: Boolean,
  type: {
    type: String,
    required: true,
    validator: value => ['depart', 'return', 'cancel'].includes(value)
  },
  title: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['close', 'submit'])

const formData = ref({
  time: null,
  notes: ''
})

// Computed
const getIcon = computed(() => {
  const icons = {
    depart: TruckIcon,
    return: ArrowPathIcon,
    cancel: XMarkIcon
  }
  return icons[props.type]
})

const getPlaceholder = computed(() => {
  const placeholders = {
    depart: 'Add departure notes...',
    return: 'Add return notes...',
    cancel: 'Reason for cancellation...'
  }
  return placeholders[props.type]
})

const getSubmitText = computed(() => {
  const texts = {
    depart: 'Record Departure',
    return: 'Record Return',
    cancel: 'Cancel Purchase'
  }
  return texts[props.type]
})

// Methods
const handleSubmit = () => {
  emit('submit', formData.value)
  resetForm()
}

const close = () => {
  resetForm()
  emit('close')
}

const resetForm = () => {
  formData.value = {
    time: null,
    notes: ''
  }
}

// Initialize time on mount
if (['depart', 'return'].includes(props.type)) {
  const now = new Date()
  formData.value.time = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`
}
</script>