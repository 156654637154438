<template>
  <div
    class="h-screen bg-white border-r transition-all duration-300 ease-in-out relative flex flex-col"
    :class="isExpanded ? 'w-64' : 'w-16'"
    @mouseenter="isExpanded = true"
    @mouseleave="isExpanded = false"
  >
    <!-- Logo Section -->
    <div class="flex items-center h-16 px-4">
      <div class="w-8 h-8 bg-red-600 rounded-full flex items-center justify-center shrink-0">
        <img src="/pitcar-modified.png" alt="PITCAR" class="w-6 h-6" />
      </div>
      <div class="ml-4 font-semibold text-gray-900 transition-all duration-300 overflow-hidden"
        :class="isExpanded ? 'opacity-100 w-auto' : 'opacity-0 w-0'"
      >
        <img src="/pitcar-logo.png" alt="PITCAR" class="w-36 h-10" />
      </div>
    </div>

    <!-- Menu Items -->
    <nav class="flex-1 mt-8 px-3">
      <div v-for="item in menuItems" :key="item.path">
        <!-- Menu dengan Submenu -->
        <template v-if="item.hasSubmenu">
          <div
            @click="toggleSubmenu(item.path)"
            class="relative flex items-center mb-2 cursor-pointer transition-all duration-200 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-50"
          >
            <div class="h-10 w-full flex items-center px-2">
              <component 
                :is="item.icon" 
                class="w-5 h-5 shrink-0 items-center text-gray-600"
              />
              
              <span
                class="ml-3 whitespace-nowrap transition-all duration-300 font-medium overflow-hidden"
                :class="isExpanded ? 'opacity-100 w-auto' : 'opacity-0 w-0'"
              >
                {{ item.name }}
              </span>

              <!-- Chevron icon -->
              <ChevronDownIcon 
                v-if="isExpanded"
                class="w-4 h-4 ml-auto transform transition-transform duration-200"
                :class="{ 'rotate-180': isSubmenuOpen(item.path) }"
              />
            </div>
          </div>

          <!-- Submenu Items -->
          <div 
            v-if="isSubmenuOpen(item.path) && isExpanded"
            class="ml-3 space-y-1"
          >
            <router-link
              v-for="subItem in item.submenu"
              :key="subItem.path"
              :to="subItem.path"
              custom
              v-slot="{ isActive, href, navigate }"
            >
              <a
                :href="href"
                @click="navigate"
                class="flex items-center h-9 pl-5 cursor-pointer transition-all duration-200 rounded-lg"
                :class="[
                  isActive 
                    ? 'text-white bg-[#B70000]' 
                    : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                ]"
              >
                <component 
                  :is="subItem.icon" 
                  class="w-4 h-4 shrink-0"
                  :class="isActive ? 'text-white' : 'text-gray-600'"
                />
                
                <span
                  class="ml-3 text-sm font-medium"
                  :class="isActive ? 'text-white' : 'text-gray-600'"
                >
                  {{ subItem.name }}
                </span>
              </a>
            </router-link>
          </div>
        </template>

        <!-- Regular Menu Items -->
        <router-link
          v-else
          :to="item.path"
          custom
          v-slot="{ isActive, href, navigate }"
        >
          <a
            :href="href"
            @click="navigate"
            class="relative flex items-center mb-2 cursor-pointer transition-all duration-200 rounded-lg"
            :class="[
              isActive 
                ? 'text-white bg-[#B70000]' 
                : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
            ]"
          >
            <div class="h-10 w-full flex items-center px-2">
              <component 
                :is="item.icon" 
                class="w-5 h-5 shrink-0 items-center"
                :class="isActive ? 'text-white' : 'text-gray-600'"
              />
              
              <span
                class="ml-3 whitespace-nowrap transition-all duration-300 font-medium overflow-hidden"
                :class="[
                  isExpanded ? 'opacity-100 w-auto' : 'opacity-0 w-0',
                  isActive ? 'text-white' : 'text-gray-600'
                ]"
              >
                {{ item.name }}
              </span>
            </div>
          </a>
        </router-link>
      </div>
    </nav>

    <!-- Add version info above user profile -->
  <div 
    class="px-3 text-sm text-gray-500 mb-2 flex justify-center items-center"
    :class="isExpanded ? 'opacity-100' : 'opacity-0'"
  >
    v{{ version }}
  </div>

    <!-- User Profile Button -->
    <div class="p-3 mt-auto border-t">
      <div class="relative">
        <button 
          class="w-full flex items-center p-2 rounded-lg hover:bg-gray-50 transition-colors duration-200"
          @click="showDropdown = !showDropdown"
        >
          <div class="w-8 h-8 rounded-full bg-gray-200 overflow-hidden shrink-0 flex items-center justify-center">
            <UserIcon class="w-5 h-5 text-gray-600" />
          </div>
          <div 
            class="ml-3 transition-all duration-300 overflow-hidden whitespace-nowrap text-left"
            :class="isExpanded ? 'opacity-100 w-auto' : 'opacity-0 w-0'"
          >
            <div class="font-medium text-gray-900 text-sm">{{ authStore.user?.name || 'User' }}</div>
            <div class="text-xs text-gray-500">{{ authStore.user?.is_admin ? 'Administrator' : 'User' }}</div>
          </div>
        </button>

        <!-- Dropdown Menu -->
        <div 
          v-if="showDropdown && isExpanded"
          class="absolute bottom-full left-0 w-full mb-2 bg-white rounded-lg shadow-lg border py-1"
        >
          <button 
            class="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50 flex items-center"
            @click="handleProfile"
          >
            <UserCircleIcon class="w-4 h-4 mr-2" />
            Profile
          </button>
          <button 
            class="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-red-50 flex items-center"
            @click="handleLogout"
          >
            <ArrowRightOnRectangleIcon class="w-4 h-4 mr-2" />
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import packageJson from '../../package.json'

import {
  HomeIcon,
  ClockIcon,
  ChartPieIcon,
  UserGroupIcon,
  Cog6ToothIcon,
  UserIcon,
  ClipboardDocumentCheckIcon,
  DocumentDuplicateIcon,
  DocumentCheckIcon,
  QueueListIcon,
  ClipboardDocumentListIcon,
  ChevronDownIcon,  // Tambahkan ini
  ChartBarSquareIcon,
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  TruckIcon,
  ShoppingCartIcon,
  ChartBarIcon
} from '@heroicons/vue/24/outline'

const router = useRouter()
const authStore = useAuthStore()
const isExpanded = ref(false)
const showDropdown = ref(false)


// Modifikasi struktur menuItems untuk mendukung submenu
const menuItems = [
  { 
    name: 'Dashboard', 
    icon: HomeIcon, 
    path: '/dashboard' 
  },
  { 
    name: 'Lead Time', 
    icon: ClockIcon, 
    path: '/lead-time' 
  },
  { 
    name: 'Laporan', 
    icon: ChartPieIcon, 
    path: '/reports' 
  },
  {
    name: 'SOP',
    icon: ClipboardDocumentListIcon,
    path: '/sop',
    hasSubmenu: true,
    submenu: [
      { 
        name: 'Master SOP', 
        icon: ClipboardDocumentCheckIcon, 
        path: '/sop/master'
      },
      { 
        name: 'Orders SOP', 
        icon: QueueListIcon, 
        path: '/sop/orders'
      },
      { 
        name: 'Validasi SOP', 
        icon: DocumentCheckIcon, 
        path: '/sop/validation'
      },
      { 
        name: 'Rangkuman SOP', 
        icon: ChartBarSquareIcon, 
        path: '/sop/summary'
      },
    ]
  },
  {
    name: 'Spareparts',
    icon: TruckIcon,
    hasSubmenu: true,
    path: '/parts',
    submenu: [
      {
        name: 'Orders',
        icon: DocumentDuplicateIcon,
        path: '/sparepart/orders' 
      },
      {
        name: 'Pembelian Spareparts',
        icon: ShoppingCartIcon,
        path: '/sparepart/purchases' 
      },
      //  {
      //    name: 'Purchase History',
      //    icon: ClockIcon,
      //    path: '/parts/history'
      //  },
       {
         name: 'Analytics',
         icon: ChartBarIcon,
         path: '/sparepart/purchases/analytics'
       }
    ]
  },
  // { 
  //   name: 'Pengaturan', 
  //   icon: Cog6ToothIcon, 
  //   path: '/setting' 
  // }
]

// Tambahkan state untuk mengelola submenu yang terbuka
const openSubmenus = ref(new Set())

// Method untuk toggle submenu
const toggleSubmenu = (path) => {
  if (openSubmenus.value.has(path)) {
    openSubmenus.value.delete(path)
  } else {
    openSubmenus.value.add(path)
  }
}

// Computed untuk mengecek apakah submenu terbuka
const isSubmenuOpen = (path) => openSubmenus.value.has(path)

const version = packageJson.version

const handleProfile = () => {
  showDropdown.value = false
  router.push('/profile')
}

const handleLogout = async () => {
  try {
    await authStore.logout()
    router.push('/login')
  } catch (error) {
    console.error('Logout failed:', error)
  }
}

// Close dropdown when clicking outside
const closeDropdown = (e) => {
  if (showDropdown.value && !e.target.closest('.relative')) {
    showDropdown.value = false
  }
}

// Lifecycle hooks
onMounted(() => {
  document.addEventListener('click', closeDropdown)
})

onUnmounted(() => {
  document.removeEventListener('click', closeDropdown)
})

</script>

<style scoped>
.router-link-active {
  background-color: inherit;
}
.absolute {
  transition: all 0.2s ease-in-out;
}
/* Optional: Add hover effect for dropdown items */
.dropdown-item {
  transition: all 0.2s ease-in-out;
}

.dropdown-item:hover {
  background-color: #F3F4F6;
}
</style>