# File: SamplingSummary.vue

<template>
  <Toaster 
    position="top-right"
    :duration="3000"
    :richColors="true"
  />
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Memuat data...</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="space-y-6">
        <!-- Header with Month Selector -->
        <div class="flex flex-col md:flex-row justify-between items-center mb-6">
          <h1 class="text-2xl font-semibold text-gray-900">Dashboard Summary SOP</h1>
          <div class="flex items-center space-x-4">
            <button 
              @click="previousMonth"
              class="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            >
              <ChevronLeftIcon class="w-5 h-5" />
            </button>
            <input 
              type="month" 
              v-model="selectedMonth"
              class="border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              :max="currentMonth"
            />
            <button 
              @click="nextMonth"
              class="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              :disabled="isCurrentMonth"
            >
              <ChevronRightIcon class="w-5 h-5" />
            </button>
          </div>
        </div>

        <!-- Main Metrics -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <!-- Total Sampling -->
          <div class="bg-gradient-to-br from-blue-500 to-blue-600 rounded-lg p-6 text-white">
            <div class="flex items-start justify-between">
              <div>
                <p class="text-blue-100">Total Sampling</p>
                <p class="text-3xl font-bold mt-2">{{ summary.total_sampling }}</p>
              </div>
              <ClipboardDocumentListIcon class="w-8 h-8 text-blue-300" />
            </div>
          </div>

          <!-- Pass Rate -->
          <div class="bg-gradient-to-br from-green-500 to-green-600 rounded-lg p-6 text-white">
            <div class="flex items-start justify-between">
              <div>
                <p class="text-green-100">Pass Rate</p>
                <p class="text-3xl font-bold mt-2">{{ summary.pass_rate.toFixed(1) }}%</p>
              </div>
              <CheckBadgeIcon class="w-8 h-8 text-green-300" />
            </div>
          </div>

          <!-- Fail Rate -->
          <div class="bg-gradient-to-br from-red-500 to-red-600 rounded-lg p-6 text-white">
            <div class="flex items-start justify-between">
              <div>
                <p class="text-red-100">Fail Rate</p>
                <p class="text-3xl font-bold mt-2">{{ summary.fail_rate.toFixed(1) }}%</p>
              </div>
              <XCircleIcon class="w-8 h-8 text-red-300" />
            </div>
          </div>

          <!-- Total Failed -->
          <div class="bg-gradient-to-br from-amber-500 to-amber-600 rounded-lg p-6 text-white">
            <div class="flex items-start justify-between">
              <div>
                <p class="text-amber-100">Total Failed</p>
                <p class="text-3xl font-bold mt-2">{{ summary.total_fail }}</p>
              </div>
              <ExclamationTriangleIcon class="w-8 h-8 text-amber-300" />
            </div>
          </div>
        </div>

        <!-- Charts Section -->
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
          <!-- Pass/Fail Distribution -->
          <div class="bg-white rounded-lg border p-6">
            <h3 class="text-lg font-medium mb-4">Pass/Fail Distribution</h3>
            <div class="h-64">
              <PieChart 
                :data="passFailData"
                :options="pieChartOptions"
              />
            </div>
          </div>

          <!-- Role Comparison -->
          <div class="bg-white rounded-lg border p-6">
            <h3 class="text-lg font-medium mb-4">Role Comparison</h3>
            <div class="h-64">
              <BarChart 
                :data="roleComparisonData"
                :options="barChartOptions"
              />
            </div>
          </div>
        </div>

        <!-- Individual Mechanic Performance -->
        <div class="bg-white rounded-lg border p-6">
          <div class="flex items-center justify-between mb-4">
            <h3 class="text-lg font-medium">Individual Mechanic Performance</h3>
            <WrenchScrewdriverIcon class="w-6 h-6 text-blue-500" />
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div v-for="mechanic in summary.mechanic_details" :key="mechanic.id" 
                 class="bg-white rounded-lg border p-4 shadow-sm">
              <div class="flex justify-between items-start mb-3">
                <div>
                  <h4 class="font-medium text-gray-900">{{ mechanic.name }}</h4>
                  <span class="text-sm text-gray-500">Rank #{{ mechanic.rank }}</span>
                </div>
                <div class="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                  Total: {{ mechanic.total }}
                </div>
              </div>
              <div class="grid grid-cols-2 gap-2">
                <div class="bg-green-50 rounded p-2">
                  <p class="text-sm text-green-600">Pass Rate</p>
                  <p class="text-lg font-semibold text-green-700">{{ mechanic.pass_rate.toFixed(1) }}%</p>
                  <p class="text-xs text-green-500">Pass: {{ mechanic.pass }}</p>
                </div>
                <div class="bg-red-50 rounded p-2">
                  <p class="text-sm text-red-600">Fail Rate</p>
                  <p class="text-lg font-semibold text-red-700">{{ mechanic.fail_rate.toFixed(1) }}%</p>
                  <p class="text-xs text-red-500">Fail: {{ mechanic.fail }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Detailed Stats Cards -->
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <!-- Service Advisor Stats -->
          <div class="bg-white rounded-lg border p-6">
            <div class="flex items-center justify-between mb-4">
              <h3 class="text-lg font-medium">Service Advisor Performance</h3>
              <UserCircleIcon class="w-6 h-6 text-blue-500" />
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div class="bg-blue-50 rounded-lg p-4">
                <p class="text-sm text-blue-600">Total</p>
                <p class="text-2xl font-bold text-blue-700">{{ summary.sa_sampling.total }}</p>
              </div>
              <div class="bg-green-50 rounded-lg p-4">
                <p class="text-sm text-green-600">Pass</p>
                <p class="text-2xl font-bold text-green-700">{{ summary.sa_sampling.pass }}</p>
              </div>
              <div class="bg-red-50 rounded-lg p-4">
                <p class="text-sm text-red-600">Fail</p>
                <p class="text-2xl font-bold text-red-700">{{ summary.sa_sampling.fail }}</p>
              </div>
            </div>
          </div>

          <!-- Mechanic Stats -->
          <div class="bg-white rounded-lg border p-6">
            <div class="flex items-center justify-between mb-4">
              <h3 class="text-lg font-medium">Mechanic Performance</h3>
              <WrenchScrewdriverIcon class="w-6 h-6 text-blue-500" />
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div class="bg-blue-50 rounded-lg p-4">
                <p class="text-sm text-blue-600">Total</p>
                <p class="text-2xl font-bold text-blue-700">{{ summary.mechanic_sampling.total }}</p>
              </div>
              <div class="bg-green-50 rounded-lg p-4">
                <p class="text-sm text-green-600">Pass</p>
                <p class="text-2xl font-bold text-green-700">{{ summary.mechanic_sampling.pass }}</p>
              </div>
              <div class="bg-red-50 rounded-lg p-4">
                <p class="text-sm text-red-600">Fail</p>
                <p class="text-2xl font-bold text-red-700">{{ summary.mechanic_sampling.fail }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { 
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardDocumentListIcon,
  CheckBadgeIcon,
  XCircleIcon,
  ExclamationTriangleIcon,
  UserCircleIcon,
  WrenchScrewdriverIcon
} from '@heroicons/vue/24/outline'
import { Toaster, toast } from 'vue-sonner'
import apiClient from '../../config/api'
import { Bar as BarChart, Pie as PieChart } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement)

// State
const loading = ref(false)
const selectedMonth = ref(new Date().toISOString().slice(0, 7))
const summary = ref({
  total_sampling: 0,
  total_pass: 0,
  total_fail: 0,
  sa_sampling: { total: 0, pass: 0, fail: 0 },
  mechanic_sampling: { total: 0, pass: 0, fail: 0 },
  pass_rate: 0,
  fail_rate: 0,
  mechanic_details: [],
  sa_details: []
})

// Computed
const currentMonth = computed(() => new Date().toISOString().slice(0, 7))
const isCurrentMonth = computed(() => selectedMonth.value === currentMonth.value)

// Chart Data
const passFailData = computed(() => ({
  labels: ['Pass', 'Fail'],
  datasets: [{
    data: [summary.value.total_pass, summary.value.total_fail],
    backgroundColor: ['#22C55E', '#EF4444'],
    borderWidth: 0
  }]
}))

const roleComparisonData = computed(() => ({
  labels: ['Service Advisor', 'Mechanic'],
  datasets: [
    {
      label: 'Pass',
      data: [summary.value.sa_sampling.pass, summary.value.mechanic_sampling.pass],
      backgroundColor: '#22C55E'
    },
    {
      label: 'Fail',
      data: [summary.value.sa_sampling.fail, summary.value.mechanic_sampling.fail],
      backgroundColor: '#EF4444'
    }
  ]
}))

// Chart Options
const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom'
    }
  }
}

const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom'
    }
  }
}

// Methods
const fetchSummary = async () => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/sop/sampling/summary', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        month: selectedMonth.value
      }
    })
    
    if (response?.data?.result?.status === 'success') {
      summary.value = response.data.result.data
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to fetch summary')
    }
  } catch (error) {
    console.error('Error fetching summary:', error)
    toast.error('Failed to load summary: ' + error.message)
  } finally {
    loading.value = false
  }
}

const previousMonth = () => {
  const date = new Date(selectedMonth.value + '-01')
  date.setMonth(date.getMonth() - 1)
  selectedMonth.value = date.toISOString().slice(0, 7)
}

const nextMonth = () => {
  if (isCurrentMonth.value) return
  const date = new Date(selectedMonth.value + '-01')
  date.setMonth(date.getMonth() + 1)
  selectedMonth.value = date.toISOString().slice(0, 7)
}

// Watch & Lifecycle
watch(selectedMonth, () => {
  fetchSummary()
})

onMounted(() => {
  fetchSummary()
})
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>