<template>
  <Toaster position="top-right" :duration="3000" :richColors="true" />
  
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Memuat data...</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="space-y-6">
        <!-- Header -->
        <div class="flex flex-col md:flex-row justify-between items-center mb-6">
          <div class="flex items-center space-x-4">
            <h1 class="text-2xl font-semibold text-gray-900">Daftar Order untuk Part Pembelian</h1>
            <div v-if="selectedOrders.length > 0" 
                 class="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium">
              {{ selectedOrders.length }} selected
            </div>
          </div>
          
          <!-- Action Buttons -->
          <button
            v-if="selectedOrders.length > 0"
            @click="openBulkPurchaseDialog"
            class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Tambah Pembelian for Selected Orders
          </button>
        </div>

        <!-- Search & Filters -->
        <div class="flex flex-col md:flex-row gap-4 mb-6">
          <div class="relative flex-1">
            <input 
              v-model="searchQuery"
              type="text"
              placeholder="Search order number, plate number..."
              class="w-full pl-10 pr-4 py-2 rounded-lg border"
              @input="handleSearch"
            >
            <MagnifyingGlassIcon class="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <!-- Filter Tabs -->
          <div class="flex gap-6">
            <button 
              v-for="tab in tabs"
              :key="tab.value"
              @click="currentTab = tab.value"
              class="py-3 relative"
              :class="[
                'font-medium',
                currentTab === tab.value 
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              ]"
            >
              <div class="flex items-center gap-2">
                {{ tab.label }}
                <span class="text-xs px-2 py-0.5 bg-gray-100 rounded-full">
                  {{ tabCounts[tab.value] || 0 }}
                </span>
              </div>
            </button>
          </div>
        </div>

        <!-- Table -->
        <div class="overflow-x-auto rounded-lg border">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr>
                <th class="py-3 px-4 text-left font-medium text-gray-700">No Order</th>
                <th class="py-3 px-4 text-left font-medium text-gray-700">Service Start</th>
                <th class="py-3 px-4 text-left font-medium text-gray-700">Car Info</th>
                <th class="py-3 px-4 text-left font-medium text-gray-700">Service Info</th>
                <th class="py-3 px-4 text-left font-medium text-gray-700">Status</th>
                <th class="py-3 px-4 text-right font-medium text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in filteredOrders" :key="order.id" class="border-t hover:bg-gray-50">
                <!-- No Order -->
                <td class="py-3 px-4">
                  <div class="font-medium">#{{ order.name }}</div>
                  <div class="text-sm text-gray-500">{{ formatDate(order.timestamps.reception) }}</div>
                </td>

                <!-- Service Start -->
                <td class="py-3 px-4">{{ formatDate(order.timestamps.service_start) }}</td>

                <!-- Car Info -->
                <td class="py-3 px-4">
                  <div class="font-medium">{{ order.car.plate }}</div>
                  <div class="text-sm text-gray-500">{{ order.car.brand }} {{ order.car.type }}</div>
                </td>

                <!-- Service Info -->
                <td class="py-3 px-4">
                  <div>Mechanic: {{ order.mechanic }}</div>
                  <div class="text-sm text-gray-500">SA: {{ order.service_advisor }}</div>
                </td>

                <!-- Status -->
                <td class="py-3 px-4">
                  <StatusBadge :status="order.part_purchase_status" />
                  <div v-if="order.part_purchases?.length" class="mt-1 space-y-1">
                    <div v-for="purchase in order.part_purchases" 
                         :key="purchase.id"
                         class="text-sm text-gray-600"
                    >
                      {{ purchase.partman }} - {{ purchase.state }}
                    </div>
                  </div>
                </td>

                <!-- Actions -->
                <td class="py-3 px-4 text-right">
                  <div class="space-y-2">
                    <!-- Need Part Button -->
                    <button
                      v-if="!order.need_part"
                      @click="confirmToggleNeedPart(order)"
                      class="w-full px-3 py-1.5 text-sm bg-white border border-blue-500 text-blue-500 rounded hover:bg-blue-50"
                    >
                      Perlu Part
                    </button>

                    <!-- Pembelian Buttons -->
                    <div v-if="order.need_part" class="space-y-2">
                      <!-- Tambah Pembelian Button -->
                      <button
                        v-if="order.part_purchase_status.code === 'pending'"
                        @click="confirmCreatePurchase(order)"
                        class="w-full px-3 py-1.5 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
                      >
                        Tambah Pembelian
                      </button>

                      <!-- Additional Pembelian Button -->
                      <button
                        v-if="order.part_purchases?.length"
                        @click="confirmCreatePurchase(order)"
                        class="w-full px-3 py-1.5 text-sm bg-white border border-blue-500 text-blue-500 rounded hover:bg-blue-50"
                      >
                        + Pembelian Lagi
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination -->
        <div 
          v-if="pagination"
          class="flex flex-col md:flex-row justify-between items-center gap-4"
        >
          <div class="flex items-center gap-4">
            <span class="text-sm text-gray-600">
              Showing {{ startNumber }} to {{ endNumber }} 
              of {{ pagination.total_items }} orders
            </span>
            
            <select
              v-model="itemsPerPage"
              @change="handleItemsPerPageChange"
              class="border rounded-lg px-2 py-1 text-sm"
            >
              <option :value="10">10 per page</option>
              <option :value="20">20 per page</option>
              <option :value="50">50 per page</option>
              <option :value="100">100 per page</option>
            </select>
          </div>

          <div class="flex items-center gap-2">
            <button
              @click="handlePageChange(pagination.current_page - 1)"
              :disabled="!pagination.has_previous"
              class="px-3 py-1 rounded-lg border disabled:opacity-50"
            >
              Previous
            </button>
            
            <template v-for="page in pageNumbers" :key="page">
              <span v-if="page === '...'" class="px-3 py-1">...</span>
              <button
                v-else
                @click="handlePageChange(page)"
                class="px-3 py-1 rounded-lg border"
                :class="pagination.current_page === page 
                  ? 'bg-blue-50 border-blue-500 text-blue-600' 
                  : 'hover:bg-gray-50'"
              >
                {{ page }}
              </button>
            </template>
            
            <button
              @click="handlePageChange(pagination.current_page + 1)"
              :disabled="!pagination.has_next"
              class="px-3 py-1 rounded-lg border disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialogs -->
    <CreatePurchaseDialog
      v-if="showCreateDialog"
      :order="selectedOrder"
      :show="showCreateDialog"
      @close="closeCreateDialog"
      @created="handlePurchaseCreated"
    />

    <PurchaseDetailsDialog
      v-if="showDetailsDialog"
      :order="selectedOrder"
      :show="showDetailsDialog"
      @close="closeDetailsDialog"
    />

    <!-- Confirmation Dialog -->
    <ConfirmationDialog
      v-if="showConfirmDialog"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      :show="showConfirmDialog"
      @confirm="handleConfirmAction"
      @cancel="closeConfirmDialog"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { Toaster, toast } from 'vue-sonner'
import debounce from 'lodash/debounce'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import CreatePurchaseDialog from './CreatePurchaseDialog.vue'
import PurchaseDetailsDialog from './DetailModal.vue'
import StatusBadge from './StatusBadge.vue'
import ConfirmationDialog from './ConfirmationDialog.vue'
import apiClient from '@/config/api'

// State
const loading = ref(false)
const orders = ref([])
const pagination = ref(null)
const searchQuery = ref('')
const itemsPerPage = ref(20)
const selectedOrders = ref([])
const currentTab = ref('all')

// Dialog state
const showCreateDialog = ref(false)
const showDetailsDialog = ref(false)
const selectedOrder = ref(null)
const showConfirmDialog = ref(false)
const confirmDialog = ref({
  title: '',
  message: '',
  action: null,
  data: null
})

// Constants
const tabs = [
  { label: 'All', value: 'all' },
  { label: 'Need Parts', value: 'need_part' },
  { label: 'Active', value: 'active' },
  { label: 'Completed', value: 'completed' }
]

const tabCounts = ref({})

// Computed
const filteredOrders = computed(() => {
  switch (currentTab.value) {
    case 'need_part':
      return orders.value.filter(order => order.need_part)
    case 'active':
      return orders.value.filter(order => 
        order.part_purchases?.some(p => ['draft', 'departed'].includes(p.state))
      )
    case 'completed':
      return orders.value.filter(order => 
        order.part_purchase_status.code === 'completed'
      )
    default:
      return orders.value
  }
})

const startNumber = computed(() => {
  if (!pagination.value) return 0
  return ((pagination.value.current_page - 1) * pagination.value.items_per_page) + 1
})

const endNumber = computed(() => {
  if (!pagination.value) return 0
  return Math.min(
    startNumber.value + pagination.value.items_per_page - 1,
    pagination.value.total_items
  )
})

const pageNumbers = computed(() => {
  if (!pagination.value) return []
  
  const total = pagination.value.total_pages
  const current = pagination.value.current_page
  const delta = 2
  const pages = []
  
  for (let i = 1; i <= total; i++) {
    if (
      i === 1 || 
      i === total || 
      (i >= current - delta && i <= current + delta)
    ) {
      pages.push(i)
    } else if (pages[pages.length - 1] !== '...') {
      pages.push('...')
    }
  }
  
  return pages
})

// Methods
const fetchData = async (params = {}) => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/part-purchase/available-orders', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: params.page || 1,
        limit: params.limit || itemsPerPage.value,
        search: searchQuery.value,
        filter: currentTab.value
      }
    })

    if (response?.data?.result?.status === 'success') {
      const result = response.data.result.data
      orders.value = result.rows
      pagination.value = result.pagination
      updateTabCounts(result.summary)
    }
  } catch (error) {
    toast.error('Failed to update part requirement')
  } finally {
    loading.value = false
  }
}

// Setelah fetchData
const toggleNeedPart = async (order) => {
  try {
    loading.value = true
    const response = await apiClient.post(`/web/part-purchase/order/${order.id}/toggle-need-part`, {
      jsonrpc: "2.0",
      method: "call"
    })

    if (response?.data?.result?.status === 'success') {
      await fetchData()
      toast.success(order.need_part 
        ? 'Kebutuhan part berhasil dihapus'
        : 'Kebutuhan part berhasil ditambahkan'
      )
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to toggle need part')
    }
  } catch (error) {
    console.error('Error toggling need part:', error)
    toast.error('Gagal mengubah kebutuhan part')
  } finally {
    loading.value = false
  }
}

// Confirmation Dialog Methods
const confirmToggleNeedPart = (order) => {
  confirmDialog.value = {
    title: order.need_part ? 'Hapus Kebutuhan Part?' : 'Tambah Kebutuhan Part?',
    message: `Apakah anda yakin ingin ${order.need_part ? 'menghapus' : 'menambah'} kebutuhan part untuk order #${order.name}?`,
    action: 'toggleNeedPart',
    data: order
  }
  showConfirmDialog.value = true
}

const confirmCreatePurchase = (order) => {
  confirmDialog.value = {
    title: 'Buat Pembelian',
    message: `Apakah anda yakin ingin membuat purchase untuk order #${order.name}?`,
    action: 'createPurchase',
    data: order
  }
  showConfirmDialog.value = true
}

const handleConfirmAction = async () => {
  try {
    const { action, data } = confirmDialog.value
    if (!data) {
      console.error('No data provided for action:', action)
      return
    }
    
    switch (action) {
      case 'toggleNeedPart':
        await toggleNeedPart(data)
        break
      case 'createPurchase':
        createPurchase(data)
        break
      default:
        console.error('Unknown action:', action)
        return
    }
    
    closeConfirmDialog()
  } catch (error) {
    console.error(`Error handling action ${confirmDialog.value.action}:`, error)
    toast.error('Terjadi kesalahan saat memproses permintaan')
  }
}

// Event Handlers
const handleSearch = debounce(() => {
  fetchData({ page: 1 })
}, 300)

const handlePageChange = (page) => {
  fetchData({ page })
}

const handleItemsPerPageChange = () => {
  fetchData({ page: 1, limit: itemsPerPage.value })
}

const createPurchase = (order) => {
  selectedOrder.value = order
  showCreateDialog.value = true
}

// Dialog Management
const closeConfirmDialog = () => {
  showConfirmDialog.value = false
  confirmDialog.value = { title: '', message: '', action: null, data: null }
}

const closeCreateDialog = () => {
  showCreateDialog.value = false
  selectedOrder.value = null
}

const closeDetailsDialog = () => {
  showDetailsDialog.value = false
  selectedOrder.value = null
}

const handlePurchaseCreated = () => {
  closeCreateDialog()
  fetchData()
  toast.success('Pembelian berhasil dibuat')
}

// Utils
const formatDate = (dateString) => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  return new Intl.DateTimeFormat('id-ID', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date)
}

const updateTabCounts = (summary) => {
  tabCounts.value = {
    all: summary.total_orders,
    need_part: summary.need_part,
    active: summary.active_purchases,
    completed: 0 // Update if available in API response
  }
}

// Lifecycle
onMounted(() => {
  fetchData()
})

// Watchers
watch(currentTab, () => {
  fetchData({ page: 1 })
})
</script>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>